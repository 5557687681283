import React, { useState, useEffect } from 'react';
import { useParams, Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Container, Paper, Chip, Skeleton, Grid, IconButton, Button, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ImageIcon from '@mui/icons-material/Image';
import EditIcon from '@mui/icons-material/Edit';
import { getGamePhrase, PhraseDetail } from '../../../services/contents_api';
import { getGameDetails } from '../../../services/api';
import { Game, Company } from '../../../types/Game';
import { Helmet } from 'react-helmet-async';
import PhraseImageGenerator from '../../../components/phrase/PhraseImageGenerator';
import ShareButtons from '../../../components/common/ShareButtons';
import RectangleAdvertisement from '../../../components/advertisement/Ad_rectangle_300';
import AdvertisementResponsive from '../../../components/advertisement/Ad_GA_res';
import { useTheme } from '@mui/material/styles';
import { isAuthenticated } from '../../../services/auth';
import LoginRequiredDialog from '../../../components/dialogs/LoginRequiredDialog';

const ContentPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: '12px',
  position: 'relative',
}));

const GameInfoPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  marginTop: theme.spacing(3),
  display: 'flex',
  gap: theme.spacing(2),
}));

const CoverImage = styled('img')({
  width: 120,
  height: 170,
  objectFit: 'cover',
  borderRadius: 8,
});

const GamePhraseDetailPage: React.FC = () => {
  const { phraseId } = useParams<{ phraseId: string }>();
  const [phrase, setPhrase] = useState<PhraseDetail | null>(null);
  const [game, setGame] = useState<Game | null>(null);
  const [loading, setLoading] = useState(true);
  const [imageGeneratorOpen, setImageGeneratorOpen] = useState(false);
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const phraseData = await getGamePhrase(Number(phraseId));
        setPhrase(phraseData);
        
        // phraseDataを取得後にゲーム情報を取得
        const gameData = await getGameDetails(phraseData.game_id);
        setGame(gameData);
      } catch (error) {
        console.error('データの取得に失敗しました:', error);
      } finally {
        setLoading(false);
      }
    };

    if (phraseId) {
      fetchData();
    }
  }, [phraseId]);

  const getPhraseType = (type: number) => {
    switch (type) {
      case 1:
        return 'キャッチコピー';
      case 2:
        return 'セリフ';
      default:
        return '';
    }
  };

  // 発売日のフォーマット関数を追加
  const formatReleaseDate = (dateString: string) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
  };

  // 会社名を取得する関数を追加
  const getPublishers = (companies: Company[] | undefined) => {
    if (!companies) return '';
    const publishers = companies.filter(c => c.role === '発売');
    return publishers.map(p => p.japanese_name || p.name).join('、');
  };

  const handleEditClick = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setLoginDialogOpen(true);
    } else {
      navigate(`/phrase/${phrase?.id}/edit`);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Skeleton variant="rectangular" height={200} sx={{ borderRadius: 2 }} />
      </Container>
    );
  }

  if (!phrase) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography>フレーズが見つかりませんでした。</Typography>
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {phrase && game 
            ? `${phrase.content.slice(0, 20)}${phrase.content.length > 20 ? '...' : ''} / ${game.japanese_name || game.name} [${getPhraseType(phrase.type)}]｜PULSE`
            : 'ローディング...'}
        </title>
      </Helmet>

      <Container maxWidth="lg" sx={{ mt: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <ContentPaper elevation={0}>
              <Box sx={{ 
                mb: 3,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start'
              }}>
                <Box>
                  <Typography variant="h6" component="div" gutterBottom sx={{ color: 'text.secondary' }}>
                    {game?.japanese_name || game?.name}
                  </Typography>
                  {phrase?.source && (
                    <Chip 
                      label={phrase.source}
                      size="small"
                      sx={{ 
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        color: '#fff',
                        fontSize: '0.7rem',
                        height: '24px',
                      }}
                    />
                  )}
                </Box>
                <Button
                  onClick={handleEditClick}
                  startIcon={<EditIcon />}
                  size="small"
                  sx={{ 
                    color: 'text.secondary',
                    '&:hover': {
                      backgroundColor: 'action.hover'
                    }
                  }}
                >
                  編集
                </Button>
              </Box>
              
              <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <FormatQuoteIcon 
                  sx={{ 
                    transform: 'scale(-1, -1)', 
                    color: 'text.secondary', 
                    fontSize: '2rem' 
                  }} 
                />
                <Typography 
                  variant="h5" 
                  component="h1"
                  sx={{ 
                    fontWeight: 500,
                    lineHeight: 1.8,
                    flex: 1
                  }}
                >
                  {phrase?.content}
                </Typography>
              </Box>

              {/* 画像生成ダイアログ */}
              <PhraseImageGenerator
                open={imageGeneratorOpen}
                onClose={() => setImageGeneratorOpen(false)}
                phrase={phrase?.content || ''}
                gameName={game?.japanese_name || game?.name || ''}
              />

              {/* 概要文 */}
              <Box sx={{ mt: 3 }}>
                <Typography 
                  variant="body1" 
                  color="text.secondary"
                  sx={{ fontSize: '0.8rem' }}
                >
                  『{phrase?.content}』とは
                  {game?.first_release_date && formatReleaseDate(game.first_release_date)}に
                  {game?.companies && getPublishers(game.companies)}から発売された
                  『{game?.japanese_name || game?.name}』の
                  {getPhraseType(phrase?.type || 0)}である。
                  {phrase?.context && <><br /><br />{phrase.context}</>}
                </Typography>

                {/* ハッシュタグリンクとシェアボタン */}
                <Box sx={{ 
                  mt: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1
                }}>
                  {/* ハッシュタグ部分 */}
                  <Box sx={{ 
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1
                  }}>
                    {game?.companies?.filter(c => c.role === '発売').map((company) => (
                      <Link 
                        key={company.company_id}
                        to={`/games/company/${company.company_id}`}
                        style={{ textDecoration: 'none', lineHeight: 1 }}
                      >
                        <Typography 
                          component="span" 
                          sx={{ 
                            color: 'secondary.main',
                            fontSize: '0.75rem',
                            '&:hover': {
                              color: 'primary.main'
                            }
                          }}
                        >
                          #{company.japanese_name || company.name}
                        </Typography>
                      </Link>
                    ))}
                    {game?.franchises?.map((franchise) => (
                      <Link 
                        key={franchise.franchise_id}
                        to={`/games/franchise/${franchise.franchise_id}`}
                        style={{ textDecoration: 'none', lineHeight: 1 }}
                      >
                        <Typography 
                          component="span" 
                          sx={{ 
                            color: 'secondary.main',
                            fontSize: '0.75rem',
                            '&:hover': {
                              color: 'primary.main'
                            }
                          }}
                        >
                          #{franchise.japanese_name || franchise.name}
                        </Typography>
                      </Link>
                    ))}
                  </Box>

                  {/* シェアボタンと画像生成ボタン */}
                  <Box sx={{ 
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center'
                  }}>
                    {game && (
                      <ShareButtons
                        text={`${phrase?.content}\n\n『${game.japanese_name || game.name}』キャッチコピー`}
                        url={`/phrase/${phraseId}`}
                        hashtags={['PULSE_DB']}
                      />
                    )}
                    <Button
                      onClick={() => setImageGeneratorOpen(true)}
                      size="small"
                      startIcon={<ImageIcon sx={{ fontSize: '14px' }} />}
                      sx={{ 
                        minWidth: 'auto',
                        p: '2px 8px',
                        fontSize: '14px',
                        textTransform: 'none',
                        lineHeight: '1',
                        borderRadius: '4px',
                        color: 'text.secondary',
                        backgroundColor: 'action.hover',
                        '&:hover': {
                          backgroundColor: 'action.selected',
                        }
                      }}
                    >
                      フレーズ画像を生成する
                    </Button>
                  </Box>
                </Box>
              </Box>
            </ContentPaper>

            <GameInfoPaper elevation={0}>
              {game?.cover_hd && (
                <CoverImage
                  src={game.cover_hd}
                  alt={game.japanese_name || game.name}
                />
              )}
              <Box>
                <Link 
                  to={`/game/${game?.game_id}`}
                  style={{ textDecoration: 'none', color: 'inherit' }}
                >
                  <Typography variant="h6" component="h2" gutterBottom>
                    {game?.japanese_name || game?.name}
                  </Typography>
                </Link>
                
                {game?.first_release_date && (
                  <Typography variant="body2" color="text.secondary" gutterBottom>
                    発売日: {new Date(game.first_release_date).getFullYear()}年
                  </Typography>
                )}
                
                {game?.companies && game.companies.length > 0 && (
                  <Box sx={{ mt: 1 }}>
                    {Object.entries(
                      game.companies.reduce((acc: { [key: string]: string[] }, company) => {
                        if (company.role) {
                          if (!acc[company.role]) {
                            acc[company.role] = [];
                          }
                          acc[company.role].push(company.japanese_name || company.name);
                        }
                        return acc;
                      }, {})
                    ).map(([role, companies], index) => (
                      <Typography key={index} variant="body2" color="text.secondary">
                        {role}: {companies.join('、')}
                      </Typography>
                    ))}
                  </Box>
                )}
              </Box>
            </GameInfoPaper>
          </Grid>

          <Grid item xs={12} md={4}>
            {isMobile ? <RectangleAdvertisement /> : <AdvertisementResponsive />}
          </Grid>
        </Grid>
      </Container>

      <LoginRequiredDialog
        open={loginDialogOpen}
        onClose={() => setLoginDialogOpen(false)}
        redirectPath={`/phrase/${phrase?.id}/edit`}
      />
    </>
  );
};

export default GamePhraseDetailPage; 