import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, CircularProgress, Box } from '@mui/material';
import PhraseEditor from '../../../components/phrase/PhraseEditor';
import { updateGamePhrase, getGamePhrase } from '../../../services/contents_api';
import { isAuthenticated } from '../../../services/auth';

const GamePhraseEditPage: React.FC = () => {
  const { phraseId } = useParams<{ phraseId: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [initialData, setInitialData] = useState<{
    content: string;
    context: string | null;
  } | null>(null);

  useEffect(() => {
    const checkAuthAndFetchData = async () => {
      const authenticated = await isAuthenticated();
      if (!authenticated) {
        navigate('/login', { 
          state: { from: `/phrase/${phraseId}/edit` }
        });
        return;
      }

      try {
        if (!phraseId) throw new Error('フレーズIDが指定されていません');
        const data = await getGamePhrase(Number(phraseId));
        setInitialData({
          content: data.content,
          context: data.context,
        });
      } catch (error) {
        console.error('フレーズの取得に失敗しました:', error);
        setError('フレーズの取得に失敗しました');
        navigate(-1);
      } finally {
        setLoading(false);
      }
    };

    checkAuthAndFetchData();
  }, [phraseId, navigate]);

  const handleSubmit = async (data: { content: string; context?: string }) => {
    try {
      if (!phraseId) throw new Error('フレーズIDが指定されていません');
      await updateGamePhrase(Number(phraseId), data);
      navigate(-1);
    } catch (error) {
      console.error('フレーズの更新に失敗しました:', error);
      setError('フレーズの更新に失敗しました');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error || !initialData) {
    return (
      <Container maxWidth="md" sx={{ mt: 4 }}>
        <Typography color="error">{error || 'フレーズが見つかりませんでした。'}</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 16 }}>
      <Paper sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          フレーズを編集
        </Typography>
        <PhraseEditor
          phraseId={Number(phraseId)}
          initialContent={initialData.content}
          initialContext={initialData.context}
          onSubmit={handleSubmit}
          onCancel={() => navigate(-1)}
        />
      </Paper>
    </Container>
  );
};

export default GamePhraseEditPage;