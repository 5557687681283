import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Advertisement336 from './Ad_GA_336';

interface AdSet {
  mainLink: string;
  mainImage: string;
  trackingImage?: string;
  width?: string;
  height?: string;
}

const AD_SETS: AdSet[] = [
  {
    // ABEMAプレミアム
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FD3PRM+4EKC+639IP",
    mainImage: "https://www29.a8.net/svt/bgt?aid=241123637929&wid=001&eno=01&mid=s00000020550001023000&mc=1",
    trackingImage: "https://www14.a8.net/0.gif?a8mat=3ZK46T+FD3PRM+4EKC+639IP",
    width: "300",
    height: "250"
  },
  {
    // Amazon特典付き
    mainLink: "https://amzn.to/4fETcTb",
    mainImage: "/image/adv/amazon_special.jpg",
  },
  {
    // ロリポップ！ゲーマーズ
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK470+8CNY1M+348+4ATCFL",
    mainImage: "https://www28.a8.net/svt/bgt?aid=241123644505&wid=002&eno=01&mid=s00000000404026003000&mc=1",
    trackingImage: "https://www13.a8.net/0.gif?a8mat=3ZK470+8CNY1M+348+4ATCFL",
    width: "300",
    height: "250"
  },
  {
    // Xserverゲームサーバー
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK471+2A5V16+CO4+2NAN35",
    mainImage: "https://www24.a8.net/svt/bgt?aid=241123645138&wid=002&eno=01&mid=s00000001642016006000&mc=1",
    trackingImage: "https://www10.a8.net/0.gif?a8mat=3ZK471+2A5V16+CO4+2NAN35",
    width: "300",
    height: "250"
  },
  {
    // MDL.make ゲーミングPC
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK471+2JOSPM+5HXK+5YZ75",
    mainImage: "https://www24.a8.net/svt/bgt?aid=241123645154&wid=002&eno=01&mid=s00000025652001003000&mc=1",
    trackingImage: "https://www10.a8.net/0.gif?a8mat=3ZK471+2JOSPM+5HXK+5YZ75",
    width: "300",
    height: "250"
  }
];

const SideAdvertisement: React.FC = () => {
  const selectedAd = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * AD_SETS.length);
    return AD_SETS[randomIndex];
  }, []);

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 3,
        mt: 3,
        width: '100%',
        position: 'sticky',
        top: '96px',
        bgcolor: 'rgba(255, 255, 255, 0.02)',
        borderRadius: 2,
        py: 2,
        px: 1.5
      }}
    >
      <Typography 
        variant="caption" 
        sx={{ 
          position: 'absolute',
          top: 8,
          right: 8,
          fontSize: '0.6rem',
          color: 'text.secondary',
          bgcolor: 'rgba(70, 70, 70, 0.75)',
          px: 0.8,
          py: 0.2,
          borderRadius: 0.5,
          letterSpacing: '0.05em',
          lineHeight: 1
        }}
      >
        PR
      </Typography>
      <Box 
        sx={{ 
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 1,
        }}
      >
        <a 
          href={selectedAd.mainLink} 
          rel="nofollow noopener noreferrer" 
          target="_blank"
        >
          <img 
            style={{ 
              maxWidth: '100%', 
              height: 'auto',
              border: '0',
              display: 'block',
            }}
            width={selectedAd.width}
            height={selectedAd.height}
            alt="Advertisement" 
            src={selectedAd.mainImage}
          />
        </a>
      </Box>
      {selectedAd.trackingImage && (
        <img 
          style={{ border: '0' }}
          width="1" 
          height="1" 
          src={selectedAd.trackingImage} 
          alt="" 
        />
      )}
      <Advertisement336 />
    </Box>
  );
};

export default SideAdvertisement;