import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { diffChars } from 'diff';

interface PhraseEditConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  originalData: {
    content: string;
    context: string | null;
  };
  newData: {
    content: string;
    context: string | null;
  };
  readOnly?: boolean;
}

const PhraseEditConfirmDialog: React.FC<PhraseEditConfirmDialogProps> = ({
  open,
  onClose,
  onConfirm,
  originalData,
  newData,
  readOnly = false,
}) => {
  const hasChanges = 
    originalData.content !== newData.content || 
    originalData.context !== newData.context;

  const renderDiff = (label: string, original: string | null, updated: string | null) => {
    if (original === updated) return null;
    
    const diff = diffChars(original || '', updated || '');
    
    return (
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
          {label}
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Box flex={1}>
            <Typography variant="caption" color="text.secondary">変更前</Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                p: 1.5,
                backgroundColor: 'action.hover',
                borderRadius: 1,
                whiteSpace: 'pre-wrap'
              }}
            >
              {diff.map((part, index) => (
                part.removed || (!part.added && !part.removed) ? (
                  <span
                    key={index}
                    style={{
                      backgroundColor: part.removed ? '#ffebee' : 'transparent',
                      textDecoration: part.removed ? 'line-through' : 'none',
                      color: part.removed ? '#d32f2f' : 'inherit'
                    }}
                  >
                    {part.value}
                  </span>
                ) : null
              ))}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="caption" color="text.secondary">変更後</Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                p: 1.5,
                backgroundColor: 'action.hover',
                borderRadius: 1,
                whiteSpace: 'pre-wrap'
              }}
            >
              {diff.map((part, index) => (
                part.added || (!part.added && !part.removed) ? (
                  <span
                    key={index}
                    style={{
                      backgroundColor: part.added ? '#e8f5e9' : 'transparent',
                      color: part.added ? '#2e7d32' : 'inherit'
                    }}
                  >
                    {part.value}
                  </span>
                ) : null
              ))}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        {readOnly ? '変更履歴の確認' : '変更内容の確認'}
      </DialogTitle>
      <DialogContent>
        {!hasChanges ? (
          <Typography color="text.secondary">
            変更はありません
          </Typography>
        ) : (
          <>
            {renderDiff('フレーズ内容', originalData.content, newData.content)}
            {renderDiff('コンテキスト', originalData.context, newData.context)}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" size="small">
          {readOnly ? '閉じる' : 'キャンセル'}
        </Button>
        {!readOnly && (
          <Button 
            onClick={onConfirm} 
            variant="contained" 
            size="small"
            disabled={!hasChanges}
          >
            更新する
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default PhraseEditConfirmDialog; 