import React, { useState } from 'react';
import { Box, Button, Divider } from '@mui/material';
import StyledTextField from '../common/StyledTextField';
import PhraseEditConfirmDialog from './PhraseEditConfirmDialog';
import PhraseHistory from './PhraseHistory';

interface PhraseEditorProps {
  phraseId?: number;
  initialContent: string;
  initialContext?: string | null;
  onSubmit: (data: { content: string; context?: string }) => Promise<void>;
  onCancel?: () => void;
}

const PhraseEditor: React.FC<PhraseEditorProps> = ({
  phraseId,
  initialContent,
  initialContext = '',
  onSubmit,
  onCancel
}) => {
  const [content, setContent] = useState(initialContent);
  const [context, setContext] = useState(initialContext || '');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const handleSubmit = async () => {
    if (!content.trim()) return;
    setConfirmDialogOpen(true);
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit({
        content: content.trim(),
        context: context.trim() || undefined,
      });
    } finally {
      setIsSubmitting(false);
      setConfirmDialogOpen(false);
    }
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5, mt: 2 }}>
        <StyledTextField
          label="フレーズ"
          value={content}
          onChange={setContent}
          required
          minRows={2}
          maxRows={4}
          placeholder="フレーズの内容を入力してください"
        />

        <StyledTextField
          label="概要"
          value={context}
          onChange={setContext}
          minRows={3}
          maxRows={6}
          placeholder="フレーズの背景情報などの概要"
        />

        <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', mt: 1 }}>
          {onCancel && (
            <Button
              onClick={onCancel}
              disabled={isSubmitting}
              size="small"
              sx={{ color: 'text.secondary' }}
            >
              キャンセル
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={!content.trim() || isSubmitting}
            size="small"
          >
            保存する
          </Button>
        </Box>

        {phraseId && (
          <>
            <Divider sx={{ my: 2 }} />
            <PhraseHistory 
              phraseId={phraseId} 
              currentPhrase={{
                content: initialContent,
                context: context
              }}
            />
          </>
        )}
      </Box>

      <PhraseEditConfirmDialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={handleConfirm}
        originalData={{
          content: initialContent,
          context: initialContext || null,
        }}
        newData={{
          content: content,
          context: context || null,
        }}
      />
    </>
  );
};

export default PhraseEditor;
