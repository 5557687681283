import React, { useState, useMemo } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  IconButton,
  Box,
  Typography
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { GameList, GameListItem, updateGameList, reorderGameListItems, removeGameFromList, searchGames, addGameToList } from '../../services/contents_api';
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { SortableGameItem } from './SortableGameItem';

interface EditGameListProps {
  open: boolean;
  onClose: () => void;
  list: GameList;
  onListUpdated: () => void;
}

const EditGameList: React.FC<EditGameListProps> = ({ open, onClose, list, onListUpdated }) => {
  const [title, setTitle] = useState(list.title);
  const [description, setDescription] = useState(list.description);
  const [visibility, setVisibility] = useState(list.visibility);
  const [items, setItems] = useState<GameListItem[]>(list.items);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<Array<{
    game_id: number;
    name: string;
    japanese_name: string | null;
  }>>([]);
  const [isSearching, setIsSearching] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // リストの基本情報を更新
      await updateGameList(list.id, {
        title,
        description,
        visibility
      });

      // アイテムの並び順を更新
      if (items.length > 0) {
        await reorderGameListItems(list.id, items);
      }

      onListUpdated();
      onClose();
    } catch (error) {
      console.error('リストの更新に失敗しました:', error);
    }
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleNoteChange = (itemId: number, newNote: string) => {
    setItems(items.map(item => 
      item.id === itemId ? { ...item, note: newNote } : item
    ));
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(async (query: string) => {
        if (query.length < 2) {
          setSearchResults([]);
          return;
        }
        try {
          const results = await searchGames(query);
          setSearchResults(results);
        } catch (error) {
          console.error('ゲーム検索に失敗しました:', error);
        } finally {
          setIsSearching(false);
        }
      }, 300),
    []
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setIsSearching(true);
    debouncedSearch(query);
  };

  const handleAddGame = async (gameId: number) => {
    try {
      const newItem = await addGameToList(list.id, gameId);
      setItems([...items, newItem]);
      setSearchQuery('');
      setSearchResults([]);
    } catch (error) {
      console.error('ゲームの追加に失敗しました:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>リストを編集</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="タイトル"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            label="説明"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth margin="dense" sx={{ mb: 3 }}>
            <InputLabel>公開設定</InputLabel>
            <Select
              value={visibility}
              label="公開設定"
              onChange={(e) => setVisibility(Number(e.target.value))}
            >
              <MenuItem value={1}>公開</MenuItem>
              <MenuItem value={0}>非公開</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="subtitle1" sx={{ mb: 1 }}>ゲーム一覧</Typography>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={items.map(item => item.id)}
              strategy={rectSortingStrategy}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: {
                    xs: 'repeat(4, 1fr)',  // スマホ
                    sm: 'repeat(auto-fill, minmax(100px, 1fr))'  // タブレット以上
                  },
                  gap: 1,
                  mb: 2
                }}
              >
                {items.map((item) => (
                  <SortableGameItem
                    key={item.id}
                    item={item}
                  />
                ))}
              </Box>
            </SortableContext>
          </DndContext>
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>ゲームを追加</Typography>
            <TextField
              fullWidth
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="ゲーム名を入力して検索..."
              InputProps={{
                startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />
              }}
            />
            {isSearching && (
              <Box sx={{ mt: 1 }}>
                <Typography variant="body2" color="text.secondary">検索中...</Typography>
              </Box>
            )}
            {searchResults.length > 0 && (
              <List sx={{ mt: 1 }}>
                {searchResults.map((game) => (
                  <ListItem
                    key={game.game_id}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { bgcolor: 'action.hover' },
                      borderRadius: 1
                    }}
                    onClick={() => handleAddGame(game.game_id)}
                  >
                    <Typography>
                      {game.japanese_name || game.name}
                    </Typography>
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="submit" variant="contained">保存</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditGameList; 