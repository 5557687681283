import React from 'react';
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // 名前付きインポートに変更
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { checkProfileCompletion, API_BASE_URL } from '../../services/api';
import { Button } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

interface GoogleLoginButtonProps {
  onLoginSuccess: (user: any) => void;
  redirectPath: string;
}

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({ onLoginSuccess, redirectPath }) => {
  const navigate = useNavigate();

  const handleGoogleLogin = async (credentialResponse: CredentialResponse) => {
    console.log('Credential Response:', credentialResponse);
    if (credentialResponse.credential) {
      const decoded: any = jwtDecode(credentialResponse.credential); // 名前付きインポートに対応
      try {
        const response = await axios.post(`${API_BASE_URL}/auth/google/login/token/`, {
          id_token: credentialResponse.credential,
        });
        
        console.log('Backend response:', response.data);
        
        // トークンを保存
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('refreshToken', response.data.refresh_token);
        
        // ユーザー情報を保存
        localStorage.setItem('user', JSON.stringify(decoded));
        
        console.log('Login successful');

        // ヘッダーの状態を更新
        onLoginSuccess(decoded);

        // プロフィールの完成度をチェック
        const isProfileComplete = await checkProfileCompletion();
        if (!isProfileComplete) {
          navigate('/profile/settings');
        } else {
          navigate(redirectPath);
        }
      } catch (error) {
        console.error('Google login failed', error);
      }
    } else {
      console.error('Credential is missing in the response');
    }
  };

  return (
    <GoogleLogin
      onSuccess={handleGoogleLogin}
      onError={() => {
        console.error('Login Failed');
      }}
      useOneTap
    />
  );
};

export default GoogleLoginButton;