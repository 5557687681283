import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { Game } from '../types/Game';
import { useNavigate } from 'react-router-dom';

interface LightweightGame {
  game_id: number;
  name: string;
  japanese_name?: string | null;
  cover?: string | null;
}

interface LightweightGameCardProps {
  game: LightweightGame;
  hideTitle?: boolean;
}

const LightweightGameCard: React.FC<LightweightGameCardProps> = ({ game, hideTitle = false }) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/game/${game.game_id}`);
  };

  return (
    <Card 
      onClick={handleCardClick}
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
        '&:hover': {
          transform: 'translateY(-5px)',
          boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        },
        backgroundColor: 'background.paper',
        borderRadius: '8px',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
    >
      <CardMedia
        component="div"
        sx={{
          height: 0,
          paddingTop: '133.33%', // 3:4のアスペクト比
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          borderRadius: '8px',
        }}
        image={game.cover || '/image/no_image.png'}
      />
      {!hideTitle && (
        <CardContent sx={{ flexGrow: 1, p: 0, pt: 1, backgroundColor: 'background.default', '&:last-child': { pb: 1.5 } }}>
          <Typography variant="subtitle2" component="h2" noWrap sx={{ lineHeight: 1, fontSize: '0.6rem', fontWeight: '500', mb: 0 }}>
            {game.japanese_name || game.name}
          </Typography>
        </CardContent>
      )}
    </Card>
  );
};

export default LightweightGameCard; 