import React, { useState } from 'react';
import { Box, Typography, Avatar, Chip, Link as MuiLink, useTheme } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Review } from '../../types/Review';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import Dialog from '../dialogs/Dialog';

interface ReviewItemProps {
  review: Review;
}

const ReviewItem: React.FC<ReviewItemProps> = ({ review }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const maxLength = 110;
  const maxLines = 3;
  const theme = useTheme();

  const scoreTags = [
    { id: 1, label: '趣味趣向補正' },
    { id: 2, label: '思い出補正' },
    { id: 3, label: 'IP・シリーズ補正' },
    { id: 4, label: 'デベロッパー補正' },
    { id: 5, label: 'ソフトウェアバージョン補正' },
    { id: 6, label: 'マルチプレイ補正' },
    { id: 7, label: 'エントリー補正' },
    { id: 8, label: 'コンテキスト補正' },
    { id: 9, label: '下方バイアス' },
    { id: 0, label: 'その他の上方補正要因' },
  ];

  const toggleExpand = () => {
    setExpanded(true);
  };

  const truncateText = (text: string) => {
    const lines = text.split('\n');
    if (lines.length > maxLines || text.length > maxLength) {
      const truncatedLines = lines.slice(0, maxLines);
      let truncatedText = truncatedLines.join('\n');
      if (truncatedText.length > maxLength) {
        truncatedText = truncatedText.slice(0, maxLength);
      }
      return `${truncatedText}...`;
    }
    return text;
  };

  const truncatedText = expanded ? review.review_text : truncateText(review.review_text);

  const formatText = (text: string) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const formatPlayDate = (startDate: string | null) => {
    if (!startDate) return '不明';
    const start = new Date(startDate);
    const now = new Date();
    const yearDiff = now.getFullYear() - start.getFullYear();
    const monthDiff = now.getMonth() - start.getMonth();
    const totalMonthDiff = yearDiff * 12 + monthDiff;

    if (totalMonthDiff < 1) {
      return '今月';
    } else if (totalMonthDiff < 12) {
      return `${totalMonthDiff}ヶ月前`;
    } else {
      return `${Math.floor(totalMonthDiff / 12)}年前`;
    }
  };

  const calculateAge = (birthDate: Date, targetDate: Date) => {
    let age = targetDate.getFullYear() - birthDate.getFullYear();
    const monthDiff = targetDate.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && targetDate.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const calculateGenerationInfo = () => {
    if (!review.first_release_date || !review.user_birth_date || !review.play_start_date) return null;
    
    const release = new Date(review.first_release_date);
    const birth = new Date(review.user_birth_date);
    const playStart = new Date(review.play_start_date);
    
    const ageAtRelease = calculateAge(birth, release);
    const ageAtPlay = calculateAge(birth, playStart);
    
    let generationType: string;

    if (ageAtRelease < 0) {
      generationType = 'GG';
    } else if (ageAtRelease <= 10) {
      generationType = 'Ch';
    } else if (ageAtRelease <= 18) {
      generationType = 'YT';
    } else {
      generationType = 'CT';
    }

    if (review.age_privacy === 0) {
      return null; // 非公開の場合は null を返す
    } else if (review.age_privacy === 1) {
      return generationType; // 曖昧な場合は世代タイプのみ返す
    } else {
      return `${generationType}${ageAtRelease >= 0 ? '+' : ''}${ageAtRelease}/${ageAtPlay}`;
    }
  };

  const generationInfo = calculateGenerationInfo();

  const getGenerationColor = (generationType: string) => {
    switch (generationType) {
      case 'GG': return theme.palette.error.light;
      case 'Ch': return theme.palette.success.light;
      case 'YT': return theme.palette.info.light;
      case 'CT': return theme.palette.warning.main;
      default: return theme.palette.grey[300];
    }
  };

  const getGenerationFullName = (generationType: string) => {
    switch (generationType) {
      case 'GG': return '後世生まれ / Generation Gap';
      case 'Ch': return '同時代体験（幼少期）/ Childhood';
      case 'YT': return '同時代体験（思春期）/ Youth';
      case 'CT': return '同時代体験 / Contemporary';
      default: return '不明';
    }
  };

  const handleGenerationClick = () => {
    setDialogOpen(true);
  };

  const calculateDaysDifference = (date1: Date, date2: Date) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const formatDate = (date: Date) => `${date.getFullYear()}年${date.getMonth() + 1}月`;

  const getAgeDescription = (age: number, agePrivacy: number): string => {
    if (agePrivacy === 2) {
      return age.toString() + '歳';
    } else if (agePrivacy === 1) {
      if (age < 10) return '10歳未満';
      if (age < 15) return '10代前半';
      if (age < 20) return '10代後半';
      const decade = Math.floor(age / 10) * 10;
      return `${decade}代${age % 10 < 5 ? '前半' : '後半'}`;
    }
    return '非公開';
  };

  const getGGDescription = (monthsDiff: number, agePrivacy: number): string => {
    if (agePrivacy === 2) {
      if (monthsDiff < 12) {
        return `発売${monthsDiff}ヶ月後に生まれ`;
      }
      const years = Math.floor(monthsDiff / 12);
      return `発売${years}年後に生まれ`;
    } else if (agePrivacy === 1) {
      if (monthsDiff < 60) return '発売後5年未満で生まれ';
      if (monthsDiff < 120) return '発売後5～10年後に生まれ';
      return '発売から10年以上経過して生まれ';
    }
    return '発売後に生まれ';
  };

  const dialogContent = (
    <>
      <Box sx={{ mb: 2 }}>
        <Box
          sx={{
            display: 'inline-block',
            bgcolor: getGenerationColor(generationInfo?.slice(0, 2) || ''),
            px: 1,
            py: 0.5,
            borderRadius: 1,
            mb: 0.5,
          }}
        >
          <Typography variant="h6" component="span" sx={{ fontWeight: 'bold' }}>
            {generationInfo}
          </Typography>
        </Box>
        <Typography variant="subtitle1" >
          {getGenerationFullName(generationInfo?.slice(0, 2) || '')}
        </Typography>
      </Box>
      <Typography paragraph>
        {(() => {
          const releaseDate = review.first_release_date ? new Date(review.first_release_date) : null;
          const birthDate = review.user_birth_date ? new Date(review.user_birth_date) : null;
          const playDate = review.play_start_date ? new Date(review.play_start_date) : null;
          const agePrivacy = review.age_privacy;
          
          if (!releaseDate || !birthDate || !playDate) return '日付情報が不足しています。';

          const ageAtRelease = calculateAge(birthDate, releaseDate);
          const ageAtPlay = calculateAge(birthDate, playDate);
          
          if (birthDate > releaseDate) {
            const monthsDiff = (birthDate.getFullYear() - releaseDate.getFullYear()) * 12 + 
                               (birthDate.getMonth() - releaseDate.getMonth());
            const ggDescription = getGGDescription(monthsDiff, agePrivacy);
            return `このレビュアーは${ggDescription}、${formatDate(playDate)}（${getAgeDescription(ageAtPlay, agePrivacy)}のとき）にプレイしました。`;
          } else {
            return `このレビュアーはゲーム発売時（${formatDate(releaseDate)}）に${getAgeDescription(ageAtRelease, agePrivacy)}で、${formatDate(playDate)}（${getAgeDescription(ageAtPlay, agePrivacy)}のとき）にプレイしました。`;
          }
        })()}
        {review.created_at ? `${formatDate(new Date(review.created_at))}にレビューを投稿しました。` : ''}
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        世代指標：
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        レビュワーの世代によって、ゲームが作られた時代の技術的制約や社会的背景への理解度が異なります。例えば、同時代を生きた人と後世に生まれた人では、ゲームの革新性や影響力の捉え方が大きく異なる可能性があります。
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
        また、ゲームの評価基準は世代によって異なる傾向があり、各世代特有の経験や知識が「良いゲーム」の捉え方に影響を与えることがあります。幼少期や青春期に体験したゲームへの感情的愛着が評価に影響を与えることもありますが、この指標によってその可能性を認識できます。
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
        世代指標やプロフィール（プレイ履歴,評価履歴）に注目することで、レビューの文脈をより深く理解でき、個々のレビュワーの経験や視点が評価にどのように影響しているかを把握しやすくなります。ゲームへの不当な低評価や過度に好意的な評価を識別する手がかりにもなるでしょう。
      </Typography>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary, mt: 1 }}>
      留意すべき点として、CT（同時代体験）のライトゲーマーと、CH,YT,GGのヘビーゲーマーや熱心な勉強家であれば、後者のほうがゲームの技術的制約や、ゲーム史、当時の社会的背景への理解度さえも習熟している可能性があります。
      </Typography>
    </>
  );

  return (
    <Box sx={{ mb: 2, p: 2, bgcolor: 'background.paper', borderRadius: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <Avatar src={review.avatar || undefined} alt={review.username} sx={{ mr: 1, width: 32, height: 32 }} />
        <Box>
          <MuiLink
            component={RouterLink}
            to={`/profile/${review.username}`}
            variant="subtitle1"
            sx={{ textDecoration: 'none', color: 'primary.main', lineHeight: 1.5 }}
          >
            {review.display_name || review.username}
          </MuiLink>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
            {review.play_time && (
              <Typography variant="caption" sx={{ display: 'inline-flex', alignItems: 'center', color: 'text.secondary' }}>
                <TimerOutlinedIcon fontSize="inherit" sx={{ mr: 0.5, fontSize: '0.85rem' }} />
                {review.play_time}時間
              </Typography>
            )}
            {review.play_start_date && (
              <Typography variant="caption" sx={{ display: 'inline-flex', alignItems: 'center', color: 'text.secondary' }}>
                <PlayCircleOutlineIcon fontSize="inherit" sx={{ mr: 0.5, fontSize: '0.85rem' }} />
                {formatPlayDate(review.play_start_date)}
              </Typography>
            )}
            {generationInfo && (
              <Typography
                variant="caption"
                onClick={handleGenerationClick}
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  bgcolor: theme.palette.background.default,
                  px: 0.5,
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                  },
                }}
              >
                {generationInfo}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', mb: 1 }}>
        {review.score !== null && (
          <Typography variant="body2" sx={{ color: 'gold', mr: 1 }}>
            ★{review.score.toFixed(1)}
          </Typography>
        )}
        {review.score_tags && review.score_tags.map((tagId) => {
          const tag = scoreTags.find(t => t.id === tagId);
          return tag && (
            <Chip
              key={tag.id}
              icon={
                tag.id === 9 ? (
                  <KeyboardDoubleArrowDownIcon style={{ color: '#79bcf3' }} />
                ) : (
                  <KeyboardDoubleArrowUpIcon style={{ color: '#f37979' }} />
                )
              }
              label={tag.label}
              size="small"
              variant="outlined"
              sx={{ 
                mr: 0.5, 
                mb: 0.5, 
                '& .MuiChip-icon': { fontSize: '0.7rem' },
                '& .MuiChip-label': { 
                  fontSize: '0.6rem',
                  py: 0.25
                }
              }}
            />
          );
        })}
      </Box>
      <Box sx={{ mb: 1, position: 'relative' }}>
        <Typography variant="body2" component="div">
          {formatText(truncatedText)}
        </Typography>
        {(review.review_text.length > maxLength || review.review_text.split('\n').length > maxLines) && !expanded && (
          <MuiLink
            component="span"
            onClick={toggleExpand}
            sx={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              cursor: 'pointer',
              color: 'primary.main',
              fontSize: '0.7rem',
              fontWeight: 'normal',
              lineHeight: '1.5',
              backgroundColor: 'background.paper',
              pl: 1,
            }}
          >
            全て読む
          </MuiLink>
        )}
      </Box>
      <Typography variant="caption" color="text.secondary">
        {new Date(review.created_at).toLocaleDateString()}に投稿
      </Typography>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title="世代指標"
        content={dialogContent}
      />
    </Box>
  );
};

export default ReviewItem;