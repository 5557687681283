import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Grid, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent, Slider, Box } from '@mui/material';
import { getPopularGamesByYear, getBasicGameStatuses } from '../services/api';
import { Game } from '../types/Game';
import GameCard from '../components/GameCard';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import Tips from '../components/dialogs/Tips';

const PopularGamesByYearPage: React.FC = () => {
  const { year: yearParam } = useParams<{ year: string }>();
  const navigate = useNavigate();
  const [games, setGames] = useState<Game[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [year, setYear] = useState(yearParam ? parseInt(yearParam) : new Date().getFullYear());
  const [ratingThresholdIndex, setRatingThresholdIndex] = useState<number>(1);
  const [gameStatuses, setGameStatuses] = useState<{ [key: number]: number | null }>({});
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(!!localStorage.getItem('token'));

  const ratingThresholds = [60, 70, 75, 80, 85];

  useEffect(() => {
    const fetchGames = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await getPopularGamesByYear(year, ratingThresholds[ratingThresholdIndex]);
        setGames(response.data.results);

        if (isLoggedIn) {
          const gameIds = response.data.results.map((game: Game) => game.game_id);
          const statusesResponse = await getBasicGameStatuses(gameIds);
          setGameStatuses(statusesResponse);
        }
      } catch (err) {
        setError('ゲームの取得中にエラーが発生しました。');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchGames();
  }, [year, ratingThresholdIndex, isLoggedIn]);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    const newYear = event.target.value as number;
    setYear(newYear);
    navigate(`/popular-games/${newYear}`);
  };

  const handleRatingChange = (event: Event, newValue: number | number[]) => {
    setRatingThresholdIndex(newValue as number);
  };

  const yearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 1990; i--) {
      years.push(i);
    }
    return years;
  };

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <>
      <Typography variant="h4" component="h1" gutterBottom>
        {year}年の人気ゲーム（グローバル）
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 4 }}>
        {year}年の人気ゲーム上位30タイトルです。グローバル指標であり、日本国内のゲーム市場に依存した指標ではありません。
      </Typography>
      {/* フィルター部分を一時的にコメントアウト
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <FormControl variant="outlined" sx={{ minWidth: 120, mr: 2 }}>
          <InputLabel id="year-select-label">年</InputLabel>
          <Select<number>
            labelId="year-select-label"
            value={year}
            onChange={handleYearChange}
            label="年"
          >
            {yearOptions().map(y => (
              <MenuItem key={y} value={y}>{y}年</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ width: 300 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography id="rating-slider" gutterBottom>
              評価スコア閾値
            </Typography>
            <Tips
              title="評価スコア閾値について"
              content={
                <Typography>
                  RatingThresholdを上げると作品評価の比重が大きくなり、より評価の高いタイトルがリストに算出されやすくなります。デフォルトの閾値は1です。
                </Typography>
              }
            />
          </Box>
          <Slider
            aria-labelledby="rating-slider"
            value={ratingThresholdIndex}
            onChange={handleRatingChange}
            valueLabelDisplay="auto"
            step={1}
            marks={[
              { value: 0, label: '0' },
              { value: 1, label: '1' },
              { value: 2, label: '2' },
              { value: 3, label: '3' },
              { value: 4, label: '4' },
            ]}
            min={0}
            max={4}
          />
        </Box>
      </Box>
      */}
      {loading ? (
        <LoadingPlaceholder type="card" count={30} />
      ) : (
        <Grid container spacing={{ xs: 1, sm: 1.5 }}>
          {games.map((game) => (
            <Grid item xs={4} sm={3} md={2} key={game.game_id}>
              <GameCard 
                game={game} 
                initialStatus={isLoggedIn ? gameStatuses[game.game_id] : null}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default PopularGamesByYearPage;
