import React, { useState } from 'react';
import { Button, Box, Typography, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { uploadAvatar } from '../../services/api';

const AvatarInput = styled('input')({
  display: 'none',
});

const AvatarPreview = styled('img')({
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  objectFit: 'cover',
  border: '2px solid #eee',
});

interface AvatarSettingsFormProps {
  currentAvatar: string | null;
  onAvatarUpdate: (newAvatarUrl: string) => void;
}

const AvatarSettingsForm: React.FC<AvatarSettingsFormProps> = ({ currentAvatar, onAvatarUpdate }) => {
  const [avatarPreview, setAvatarPreview] = useState<string | null>(currentAvatar);
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setError('画像サイズは2MB以下にしてください');
        return;
      }

      if (!['image/jpeg', 'image/png'].includes(file.type)) {
        setError('JPEG、PNG形式の画像のみ対応しています');
        return;
      }

      setAvatarFile(file);
      setError(null);
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!avatarFile) return;

    setIsUploading(true);
    setError(null);

    try {
      const formData = new FormData();
      formData.append('avatar', avatarFile);
      
      const response = await uploadAvatar(formData);
      if (response.avatar_url) {
        onAvatarUpdate(response.avatar_url);
        setAvatarFile(null);
      }
    } catch (error: any) {
      console.error('Avatar upload error:', error);
      setError(
        error.response?.data?.avatar?.[0] || 
        error.response?.data?.error || 
        'アバターの更新に失敗しました'
      );
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Paper sx={{ p: 2, mb: 3 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>アバター設定</Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
        <AvatarPreview
          src={avatarPreview || '/default-avatar.png'}
          alt="アバタープレビュー"
        />
        <label htmlFor="avatar-input">
          <AvatarInput
            accept="image/jpeg,image/png,image/gif"
            id="avatar-input"
            type="file"
            onChange={handleAvatarChange}
          />
          <Button
            variant="outlined"
            component="span"
            disabled={isUploading}
          >
            画像を選択
          </Button>
        </label>
        {avatarFile && (
          <Button
            type="submit"
            variant="contained"
            disabled={isUploading}
          >
            {isUploading ? 'アップロード中...' : '保存'}
          </Button>
        )}
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        <Typography variant="caption" color="text.secondary">
          2MB以下のJPEG、PNG形式の画像を使用できます
        </Typography>
      </Box>
    </Paper>
  );
};

export default AvatarSettingsForm;
