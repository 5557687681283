import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { getGameDescription } from '../../services/api';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionUploadDialog from './DescriptionUploadDialog';
import Expandable from '../common/Expandable';

interface GameDescriptionSectionProps {
  gameId: number;
}

interface Description {
  id: number;
  description: string | null;
  story: string | null;
}

// コンテンツの型定義を追加
interface ContentPart {
  type: 'text' | 'image';
  content: string;
}

const GameDescriptionSection: React.FC<GameDescriptionSectionProps> = ({ gameId }) => {
  const [description, setDescription] = useState<Description | null>(null);
  const [loading, setLoading] = useState(true);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const fetchDescription = async () => {
    try {
      const response = await getGameDescription(gameId);
      setDescription(response.data);
    } catch (error) {
      console.error('概要の取得に失敗しました:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDescription();
  }, [gameId]);

  const handleUploadComplete = () => {
    fetchDescription();
    setUploadDialogOpen(false);
  };

  // URLを画像とテキストに分割する関数を修正
  const parseContent = (text: string): ContentPart[] => {
    if (!text) return [];
    
    const urlRegex = /(https?:\/\/[^\s]+\.(?:jpg|jpeg|png|gif|webp))/gi;
    let lastIndex = 0;
    const result: ContentPart[] = [];
    let match;

    while ((match = urlRegex.exec(text)) !== null) {
      // URLの前のテキストを追加
      if (match.index > lastIndex) {
        result.push({
          type: 'text',
          content: text.slice(lastIndex, match.index)
        });
      }

      // 画像URLを追加
      result.push({
        type: 'image',
        content: match[0]
      });

      lastIndex = match.index + match[0].length;
    }

    // 残りのテキストを追加
    if (lastIndex < text.length) {
      result.push({
        type: 'text',
        content: text.slice(lastIndex)
      });
    }

    return result;
  };

  // コンテンツをレンダリングする関数を修正
  const renderContent = (text: string) => {
    if (!text) return null;
    
    const parts = parseContent(text);
    
    return parts.map((part, index) => {
      if (part.type === 'image') {
        return (
          <Box 
            key={index} 
            sx={{ 
              my: 2,
              '& img': {
                maxWidth: '100%',
                height: 'auto',
                borderRadius: 1,
                display: 'block',
                backgroundColor: 'rgba(0, 0, 0, 0.1)'
              }
            }}
          >
            <img src={part.content} alt="" loading="lazy" />
          </Box>
        );
      } else {
        return (
          <Typography 
            key={index}
            variant="body2" 
            component="span"
            sx={{ 
              whiteSpace: 'pre-wrap',
              color: 'text.secondary',
              display: 'block'
            }}
          >
            {part.content}
          </Typography>
        );
      }
    });
  };

  if (loading) {
    return null;
  }

  if (!description?.description && !description?.story) {
    // 本番環境では何も表示しない
    if (process.env.NODE_ENV !== 'development') {
      return null;
    }

    // 開発環境のみ表示
    return (
      <Box sx={{ mt: 4, mb: 2 }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          mb: 2 
        }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 0 }}>概要</Typography>
          <Button
            startIcon={<EditIcon />}
            onClick={() => setUploadDialogOpen(true)}
            size="small"
          >
            追加
          </Button>
        </Box>
        <Typography variant="body2" color="text.secondary">
          このゲームの概要はまだ登録されていません。
        </Typography>
        <DescriptionUploadDialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          gameId={gameId}
          onUploadComplete={handleUploadComplete}
          initialDescription={description}
        />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4, mb: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Typography variant="h6" component="h2" gutterBottom sx={{ mb: 0 }}>概要</Typography>
        {process.env.NODE_ENV === 'development' && (
          <IconButton
            size="small"
            onClick={() => setUploadDialogOpen(true)}
            sx={{ color: 'text.secondary' }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
      <Expandable maxHeight={105}>
        {description.description && (
          <Box sx={{ mb: description.story ? 2 : 0 }}>
            {renderContent(description.description)}
          </Box>
        )}
        {description.story && (
          <>
            {description.description && (
              <Typography variant="subtitle2" component="h3" sx={{ mt: 2, mb: 1 }}>ストーリー</Typography>
            )}
            <Box>
              {renderContent(description.story)}
            </Box>
          </>
        )}
      </Expandable>
      <DescriptionUploadDialog
        open={uploadDialogOpen}
        onClose={() => setUploadDialogOpen(false)}
        gameId={gameId}
        onUploadComplete={handleUploadComplete}
        initialDescription={description}
      />
    </Box>
  );
};

export default GameDescriptionSection;