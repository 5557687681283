import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface LoginRequiredDialogProps {
  open: boolean;
  onClose: () => void;
  redirectPath?: string;
}

const LoginRequiredDialog: React.FC<LoginRequiredDialogProps> = ({
  open,
  onClose,
  redirectPath
}) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    onClose();
    navigate('/login', { 
      state: { from: redirectPath }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>ログインが必要です</DialogTitle>
      <DialogContent>
        <Typography>
          この機能を利用するにはログインが必要です。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">
          キャンセル
        </Button>
        <Button onClick={handleLogin} variant="contained" autoFocus>
          ログインする
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginRequiredDialog; 