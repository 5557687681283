import React, { useState } from 'react';
import { Box, Link, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface ExpandableProps {
  children: React.ReactNode;
  maxHeight?: number;
  maxLines?: number;
  expandLabel?: string;
  collapseLabel?: string;
  expandButtonStyle?: 'text' | 'icon' | 'pill';
  iconSize?: number;
  iconPadding?: number;
  iconColor?: string;
  iconBgColor?: string;
}

const ExpandableContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'maxHeight' && prop !== 'maxLines' && prop !== 'isExpanded'
})<{ maxHeight?: number; maxLines?: number; isExpanded: boolean }>(
  ({ maxHeight, maxLines, isExpanded }) => ({
    position: 'relative',
    ...(maxHeight && {
      maxHeight: isExpanded ? 'none' : maxHeight,
    }),
    ...(maxLines && {
      display: isExpanded ? 'block' : '-webkit-box',
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
    }),
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  })
);

const TextExpandButton = styled(Link)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  cursor: 'pointer',
  fontSize: '0.65rem',
  fontWeight: 'normal',
  lineHeight: '1.5',
  backgroundColor: 'background.paper',
  paddingLeft: '8px',
  zIndex: 1,
  transform: 'translateY(100%)',
});

const IconExpandButton = styled(IconButton)<{ padding: number }>(({ padding }) => ({
  position: 'absolute',
  bottom: padding,
  right: padding,
  padding: padding,
  minWidth: 'auto',
  minHeight: 'auto',
  zIndex: 1,
  transform: 'translateY(50%)',
}));

const PillExpandButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 4,
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: 'rgba(0, 0, 0, 0.3)',
  color: '#a7a7a7',
  zIndex: 1,
  width: '120px',
  height: '28px',
  borderRadius: '8px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
}));

const Expandable: React.FC<ExpandableProps> = ({
  children,
  maxHeight,
  maxLines,
  expandLabel = '全て表示',
  collapseLabel = '折りたたむ',
  expandButtonStyle = 'text',
  iconSize = 20,
  iconPadding = 4,
  iconColor = 'primary',
  iconBgColor = 'transparent',
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [needsExpand, setNeedsExpand] = useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (contentRef.current) {
      if (maxHeight) {
        setNeedsExpand(contentRef.current.scrollHeight > maxHeight);
      } else if (maxLines) {
        const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
        const totalHeight = contentRef.current.scrollHeight;
        const numberOfLines = Math.ceil(totalHeight / lineHeight);
        setNeedsExpand(numberOfLines > maxLines);
      }
    }
  }, [children, maxHeight, maxLines]);

  const renderExpandButton = () => {
    switch (expandButtonStyle) {
      case 'icon':
        return (
          <IconExpandButton
            onClick={() => setIsExpanded(!isExpanded)}
            padding={iconPadding}
            sx={{
              color: iconColor,
              bgcolor: iconBgColor,
              '&:hover': {
                bgcolor: iconBgColor === 'transparent' ? 'rgba(0, 0, 0, 0.04)' : iconBgColor,
              },
            }}
          >
            {isExpanded ? (
              <ExpandLessIcon sx={{ fontSize: iconSize }} />
            ) : (
              <ExpandMoreIcon sx={{ fontSize: iconSize }} />
            )}
          </IconExpandButton>
        );
      case 'pill':
        return (
          <PillExpandButton
            onClick={() => setIsExpanded(!isExpanded)}
            size="small"
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: iconSize
              }
            }}
          >
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </PillExpandButton>
        );
      default:
        return (
          <TextExpandButton
            onClick={() => setIsExpanded(!isExpanded)}
            color="primary"
          >
            {isExpanded ? collapseLabel : expandLabel}
          </TextExpandButton>
        );
    }
  };

  return (
    <Box sx={{ 
      position: 'relative',
      pb: needsExpand && expandButtonStyle === 'icon' ? 1 : 0,
    }}>
      <ExpandableContent
        ref={contentRef}
        maxHeight={maxHeight}
        maxLines={maxLines}
        isExpanded={isExpanded}
      >
        {children}
      </ExpandableContent>
      {needsExpand && renderExpandButton()}
    </Box>
  );
};

export default Expandable;