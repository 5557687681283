import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { addGamePhrase } from '../../services/contents_api';
import { isAuthenticated } from '../../services/auth';
import LoginRequiredDialog from '../dialogs/LoginRequiredDialog';
import { useLocation } from 'react-router-dom';

interface PhraseUploadButtonProps {
  gameId: number;
  onUploadComplete?: () => void;
  onError?: (error: string) => void;
}

const PhraseUploadButton: React.FC<PhraseUploadButtonProps> = ({ gameId, onUploadComplete, onError }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState('');
  const [context, setContext] = useState('');
  const [source, setSource] = useState('');
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const location = useLocation();

  const handleSubmit = async () => {
    if (!content) return;

    setLoading(true);
    try {
      await addGamePhrase({
        game_id: gameId,
        content,
        context: context || undefined,
        source: source || undefined,
      });

      if (onUploadComplete) {
        onUploadComplete();
      }
      setOpen(false);
      resetForm();
    } catch (error) {
      console.error('キャッチコピーの追加に失敗しました:', error);
      if (onError) {
        onError('キャッチコピーの追加に失敗しました');
      }
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setContent('');
    setContext('');
    setSource('');
  };

  const handleAddClick = async () => {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      setShowLoginDialog(true);
      return;
    }
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        onClick={handleAddClick}
        sx={{ 
          borderRadius: '12px',
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'transparent'
          }
        }}
      >
        add
      </Button>

      <LoginRequiredDialog
        open={showLoginDialog}
        onClose={() => setShowLoginDialog(false)}
        redirectPath={location.pathname}
      />

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          キャッチコピーの追加
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="キャッチコピー"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />

          <TextField
            fullWidth
            label="概要"
            value={context}
            onChange={(e) => setContext(e.target.value)}
            margin="normal"
            multiline
            rows={2}
            helperText="キャッチコピーの背景情報などの概要"
          />

          {process.env.NODE_ENV === 'development' && (
            <TextField
              fullWidth
              label="出典"
              value={source}
              onChange={(e) => setSource(e.target.value)}
              margin="normal"
              helperText="公式サイト、パッケージ、CMなど"
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>
            キャンセル
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            disabled={!content || loading}
          >
            {loading ? '追加中...' : '追加'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PhraseUploadButton;