import React, { useRef, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface ListThumbnailGeneratorProps {
  items: Array<{
    game_cover: string | null;
    game_name: string;
  }>;
  listTitle: string;
  userName: string;
}

const ListThumbnailGenerator: React.FC<ListThumbnailGeneratorProps> = ({ items, listTitle, userName }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const generateThumbnail = async () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // 16:9のアスペクト比に修正
    canvas.width = 1200;
    canvas.height = 750;

    // モダンなグラデーション背景
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
    gradient.addColorStop(0, '#0F0F13');    // ダークブルーがかったブラック
    gradient.addColorStop(0.4, '#151620');   // ミッドナイトブルー
    gradient.addColorStop(1, '#1A1B25');    // スレートブルー
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // スタイリッシュなアクセントグラデーション
    const accentGradient1 = ctx.createRadialGradient(
      0, 0, 0,
      0, 0, canvas.width
    );
    accentGradient1.addColorStop(0, 'rgba(190, 255, 0, 0.15)');
    accentGradient1.addColorStop(0.3, 'rgba(190, 255, 0, 0.05)');
    accentGradient1.addColorStop(1, 'rgba(190, 255, 0, 0)');
    ctx.fillStyle = accentGradient1;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 2つ目のアクセントグラデーション（右下から）
    const accentGradient2 = ctx.createRadialGradient(
      canvas.width, canvas.height, 0,
      canvas.width, canvas.height, canvas.width * 0.8
    );
    accentGradient2.addColorStop(0, 'rgba(0, 200, 255, 0.1)');  // シアン系アクセント
    accentGradient2.addColorStop(0.4, 'rgba(0, 200, 255, 0.03)');
    accentGradient2.addColorStop(1, 'rgba(0, 200, 255, 0)');
    ctx.fillStyle = accentGradient2;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 洗練されたノイズテクスチャ
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    for (let i = 0; i < data.length; i += 4) {
      const noise = Math.random() * 2;  // よりソフトなノイズ
      data[i] = Math.min(data[i] + noise, 255);
      data[i + 1] = Math.min(data[i + 1] + noise, 255);
      data[i + 2] = Math.min(data[i + 2] + noise, 255);
    }
    ctx.putImageData(imageData, 0, 0);

    // コンテンツエリアの設定（左右の余白を統一）
    const contentPadding = 80;  // 左右の余白を増やして実質的な幅を狭く
    const contentWidth = canvas.width - (contentPadding * 2);

    // ロゴの読み込みと描画（右上に配置）
    const logo = new Image();
    logo.crossOrigin = "anonymous"; 
    logo.src = '/pulse_logo.png';
    await new Promise(resolve => logo.onload = resolve);
    const logoHeight = 40;
    const logoWidth = (logo.width / logo.height) * logoHeight;
    ctx.drawImage(logo, canvas.width - logoWidth - contentPadding + 40, 30, logoWidth, logoHeight);  // 上の余白を40pxに

    // タイトルの描画（ロゴより下に配置）
    ctx.fillStyle = 'rgba(255, 255, 255, 0.95)';
    ctx.font = 'bold 28px "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", sans-serif';
    const titleText = listTitle;
    let titleMetrics = ctx.measureText(titleText);
    const titleY = 110;  // ロゴとの間に適切な余白を確保
    
    if (titleMetrics.width > contentWidth) {
      let truncatedTitle = titleText;
      while (ctx.measureText(truncatedTitle + '...').width > 850 && truncatedTitle.length > 0) {
        truncatedTitle = truncatedTitle.slice(0, -1);
      }
      ctx.fillText(truncatedTitle + '...', contentPadding, titleY);
    } else {
      ctx.fillText(titleText, contentPadding, titleY);
    }

    // サブテキストの描画
    ctx.fillStyle = 'rgba(255, 255, 255, 0.7)';
    ctx.font = '22px sans-serif';
    const collectionText = `${items.length} Games by @${userName}`;
    ctx.fillText(collectionText, contentPadding, titleY + 35);

    // ゲームカバーの配置を中央寄せに
    const coverWidth = 170;
    const coverHeight = coverWidth * (4/3);
    const gapX = 24;
    const rowCount = 5;
    const totalGapsWidth = gapX * (rowCount - 1);
    const availableWidth = contentWidth;
    const adjustedCoverWidth = (availableWidth - totalGapsWidth) / rowCount;
    const adjustedCoverHeight = adjustedCoverWidth * (4/3);
    const startX = contentPadding;
    const startY = titleY + 80;  // タイトルからの距離を調整
    const maxDisplayCovers = Math.min(items.length, 10);

    // 角丸の描画関数
    const drawRoundedImage = (image: HTMLImageElement, x: number, y: number, w: number, h: number) => {
      const radius = 8; // 角丸の半径
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + w - radius, y);
      ctx.quadraticCurveTo(x + w, y, x + w, y + radius);
      ctx.lineTo(x + w, y + h - radius);
      ctx.quadraticCurveTo(x + w, y + h, x + w - radius, y + h);
      ctx.lineTo(x + radius, y + h);
      ctx.quadraticCurveTo(x, y + h, x, y + h - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(image, x, y, w, h);
      ctx.restore();
    };

    // カバー画像の描画
    for (let i = 0; i < maxDisplayCovers; i++) {
      const item = items[i];
      if (!item.game_cover) continue;
      
      const cover = new Image();
      cover.crossOrigin = "anonymous";
      cover.src = item.game_cover;
      await new Promise(resolve => cover.onload = resolve);
      
      const x = startX + (i % 5) * (adjustedCoverWidth + gapX);
      const y = startY + Math.floor(i / 5) * (adjustedCoverHeight + gapX);
      
      // シャドウ効果
      ctx.shadowColor = 'rgba(0, 0, 0, 0.4)';
      ctx.shadowBlur = 20;
      ctx.shadowOffsetX = 0;
      ctx.shadowOffsetY = 8;
      
      // 角丸付きでカバー画像を描画
      drawRoundedImage(cover, x, y, adjustedCoverWidth, adjustedCoverHeight);

      // 10番目のカバーで、かつ追加のゲームがある場合
      if (i === 9 && items.length > 10) {
        // 半透明のオーバーレイ（角丸に合わせる）
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x + 8, y);
        ctx.lineTo(x + adjustedCoverWidth - 8, y);
        ctx.quadraticCurveTo(x + adjustedCoverWidth, y, x + adjustedCoverWidth, y + 8);
        ctx.lineTo(x + adjustedCoverWidth, y + adjustedCoverHeight - 8);
        ctx.quadraticCurveTo(x + adjustedCoverWidth, y + adjustedCoverHeight, x + adjustedCoverWidth - 8, y + adjustedCoverHeight);
        ctx.lineTo(x + 8, y + adjustedCoverHeight);
        ctx.quadraticCurveTo(x, y + adjustedCoverHeight, x, y + adjustedCoverHeight - 8);
        ctx.lineTo(x, y + 8);
        ctx.quadraticCurveTo(x, y, x + 8, y);
        ctx.closePath();
        ctx.fillStyle = 'rgba(0, 0, 0, 0.75)';
        ctx.shadowColor = 'transparent';
        ctx.fill();
        ctx.restore();

        // 残りの数を表示
        const remainingGames = items.length - 10;
        ctx.fillStyle = 'white';
        ctx.font = 'bold 48px "Helvetica Neue", Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(`+${remainingGames}`, x + adjustedCoverWidth/2, y + adjustedCoverHeight/2);

        // テキストの下に "more games" を追加
        ctx.font = '20px "Helvetica Neue", Arial';
        ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
        ctx.fillText('more games', x + adjustedCoverWidth/2, y + adjustedCoverHeight/2 + 35);
      }
    }

    // シャドウをリセット
    ctx.shadowColor = 'transparent';
    ctx.shadowBlur = 0;
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    // モバイルデバイスの場合は新しいタブで開く
    if (/Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const image = canvas.toDataURL('image/png');
      // Base64データをBlobに変換
      const byteString = atob(image.split(',')[1]);
      const mimeString = image.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      
      // BlobからURLを作成
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      
      // メモリリーク防止のため、URLを解放
      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } else {
      // PCの場合は従来通りダウンロード
      const link = document.createElement('a');
      link.download = `${listTitle}-thumbnail.png`;
      link.href = canvas.toDataURL('image/png');
      link.click();
    }
  };

  useEffect(() => {
    generateThumbnail();
  }, [items, listTitle, userName]);

  return (
    <Box sx={{ 
      mt: 2,
      maxWidth: '600px',  // コンテナの最大幅を制限
    }}>
      <Box sx={{ 
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 1,
        overflow: 'hidden'
      }}>
        <canvas
          ref={canvasRef}
          style={{
            width: '100%',
            height: 'auto',
          }}
        />
        <Box sx={{ 
          p: 1.5,
          borderTop: '1px solid',
          borderColor: 'divider',
          bgcolor: theme => theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.03)' : 'rgba(0,0,0,0.02)',
          display: 'flex',
          flexDirection: 'column',
          gap: 1.5
        }}>
          <Typography 
            variant="caption" 
            sx={{ 
              color: 'text.secondary',
              fontSize: '0.7rem',
              lineHeight: 1.2
            }}
          >
            サムネイルはダウンロードしてシェアテキストと併せて利用してください。
            <br />
            画像単体での利用はお控えください。
          </Typography>
          <Button
            size="small"
            fullWidth
            startIcon={<DownloadIcon sx={{ fontSize: '1rem' }} />}
            onClick={handleDownload}
            sx={{ 
              fontSize: '0.75rem',
              py: 0.5,
              textTransform: 'none'
            }}
          >
            ダウンロード
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ListThumbnailGenerator; 