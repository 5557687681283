import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUserProfileByUsername } from '../services/api';
import ProfilePage from './ProfilePage';
import { UserProfile } from '../types/UserProfile';
import { Typography, Box, Paper, Link } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../constants/site';
import { getCurrentUserId, isAuthenticated } from '../services/auth';

const PublicProfilePage: React.FC = () => {
  const { username } = useParams<{ username: string }>();
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      if (username) {
        try {
          const data = await getUserProfileByUsername(username);
          setProfile(data);
        } catch (error) {
          console.error('プロフィールの取得に失敗しました', error);
          setError('プロフィールの取得に失敗しました。');
        }
      }
    };

    fetchProfile();
  }, [username]);

  useEffect(() => {
    const checkIfOwnProfile = async () => {
      if (profile && await isAuthenticated()) {
        const currentUserId = await getCurrentUserId();
        setIsOwnProfile(currentUserId === profile.user_id);
      } else {
        setIsOwnProfile(false);
      }
    };

    checkIfOwnProfile();
  }, [profile]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>プロフィールが見つかりません | {SITE_NAME}</title>
        </Helmet>
        <Typography color="error">{error}</Typography>
      </>
    );
  }

  return (
    <>
      <ProfilePage profile={profile} isPublic={true} />
      
      {isOwnProfile && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            p: 2,
            textAlign: 'center',
            bgcolor: 'background.paper',
            borderTop: 2,
            borderColor: 'primary.main',
            zIndex: 1000
          }}
        >
          <Typography variant="body2">
            現在パブリックページを表示しています。個人ページに戻る場合は
            <Link href="/profile" >
            こちら
            </Link>
          </Typography>
        </Paper>
      )}
    </>
  );
};

export default PublicProfilePage;
