import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import AdvertisementResponsive from './Ad_GA_res';

interface AdSet {
  mainLink: string;
  mainImage: string | {
    desktop: string;
    mobile: string;
  };
  trackingImage?: string;
  width: string | {
    desktop: string;
    mobile: string;
  };
  height: string | {
    desktop: string;
    mobile: string;
  };
}

const AD_SETS: AdSet[] = [
  {
    // ABEMAプレミアム
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FD3PRM+4EKC+62MDD",
    mainImage: "https://www20.a8.net/svt/bgt?aid=241123637929&wid=001&eno=01&mid=s00000020550001020000&mc=1",
    trackingImage: "https://www14.a8.net/0.gif?a8mat=3ZK46T+FD3PRM+4EKC+62MDD",
    width: {
      desktop: "600",
      mobile: "600"
    },
    height: {
      desktop: "94",
      mobile: "94"
    }
  },
  {
    // DMMブックス
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FH9R02+6HW+3SYRPT",
    mainImage: "https://www28.a8.net/svt/bgt?aid=241123637936&wid=001&eno=01&mid=s00000000842023005000&mc=1",
    trackingImage: "https://www10.a8.net/0.gif?a8mat=3ZK46T+FH9R02+6HW+3SYRPT",
    width: {
      desktop: "728",
      mobile: "728"
    },
    height: {
      desktop: "90",
      mobile: "90"
    }
  },
  {
    // DMM FX
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+EKIWQA+1WP2+6DC69",
    mainImage: "https://www25.a8.net/svt/bgt?aid=241123637881&wid=001&eno=01&mid=s00000008903001070000&mc=1",
    trackingImage: "https://www16.a8.net/0.gif?a8mat=3ZK46T+EKIWQA+1WP2+6DC69",
    width: {
      desktop: "728",
      mobile: "728"
    },
    height: {
      desktop: "90",
      mobile: "90"
    }
  },
  {
    // goodsEC
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46W+7CXWZ6+4RNG+61RI9",
    mainImage: "https://www20.a8.net/svt/bgt?aid=241123640445&wid=001&eno=01&mid=s00000022246001016000&mc=1",
    trackingImage: "https://www14.a8.net/0.gif?a8mat=3ZK46W+7CXWZ6+4RNG+61RI9",
    width: {
      desktop: "468",
      mobile: "468"
    },
    height: {
      desktop: "60",
      mobile: "60"
    }
  },
  {
    // Amazon特典付き
    mainLink: "https://amzn.to/4fETcTb",
    mainImage: "/image/adv/amazon_special.jpg",
    width: "800",
    height: "400"
  }
  // {
  //   // Amazonブラックフライデー
  //   mainLink: "https://amzn.to/3AWw4k0",
  //   mainImage: {
  //     desktop: "https://images-fe.ssl-images-amazon.com/images/G/09/2024/x-site/Black_Friday/wb3my7s/LU/EP/BF24_LUEP_02_hero_dt_Jp_2560x600.jpg",
  //     mobile: "https://images-fe.ssl-images-amazon.com/images/G/09/2024/x-site/Black_Friday/wb3my7s/LU/EP/BF24_LUEP_03_hero_mb_Jp_750x350.jpg"
  //   },
  //   width: {
  //     desktop: "2560",
  //     mobile: "750"
  //   },
  //   height: {
  //     desktop: "600",
  //     mobile: "350"
  //   }
  // }
];

const Advertisement: React.FC = () => {
  const shouldShowAdSense = useMemo(() => Math.random() < 0.9, []);
  const selectedAd = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * AD_SETS.length);
    return AD_SETS[randomIndex];
  }, []);

  if (shouldShowAdSense) {
    return <AdvertisementResponsive />;
  }

  const isBlackFriday = typeof selectedAd.mainImage === 'object';
  const isMobile = window.innerWidth <= 768;

  const getResponsiveValue = (
    value: string | { desktop: string; mobile: string },
    isMobile: boolean
  ): string => {
    if (typeof value === 'string') return value;
    return isMobile ? value.mobile : value.desktop;
  };

  return (
    <Box 
      sx={{ 
        display: 'flex',
        justifyContent: 'center',
        my: 3,
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
        bgcolor: 'rgba(255, 255, 255, 0.02)',
        borderRadius: 1,
        py: 2,
        px: 1.5
      }}
    >
      <Typography 
        variant="caption" 
        sx={{ 
          position: 'absolute',
          top: 8,
          right: 8,
          fontSize: '0.6rem',
          color: 'text.secondary',
          bgcolor: 'rgba(70, 70, 70, 0.75)',
          px: 0.8,
          py: 0.2,
          borderRadius: 0.5,
          letterSpacing: '0.05em',
          lineHeight: 1,
          zIndex: 1
        }}
      >
        PR
      </Typography>
      <a 
        href={selectedAd.mainLink} 
        rel="nofollow noopener noreferrer" 
        target="_blank"
      >
        <img 
          style={{ 
            maxWidth: '100%', 
            height: 'auto',
            border: '0',
            display: 'block',
          }}
          width={getResponsiveValue(selectedAd.width, isMobile)}
          height={getResponsiveValue(selectedAd.height, isMobile)}
          alt="Advertisement" 
          src={getResponsiveValue(selectedAd.mainImage, isMobile)}
        />
      </a>
      {selectedAd.trackingImage && (
        <img 
          style={{ border: '0' }}
          width="1" 
          height="1" 
          src={selectedAd.trackingImage} 
          alt="" 
        />
      )}
    </Box>
  );
};

export default Advertisement;