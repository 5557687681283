import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { SITE_NAME } from '../../constants/site';

const PrivacyPolicyPage: React.FC = () => {
  const Section: React.FC<{ title: string; intro?: string; items?: string[]; text?: string; index: number }> = ({ title, intro, items, text, index }) => (
    <Box sx={{ '&:not(:first-of-type)': { mt: 4 } }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
        {`${index + 1}. ${title}`}
      </Typography>
      {intro && <Typography sx={{ mb: 2, color: 'text.secondary' }}>{intro}</Typography>}
      {items && (
        <Box component="ul" sx={{ pl: 2, m: 0, mb: text ? 2 : 0 }}>
          {items.map((item, index) => (
            <Typography component="li" key={index} sx={{ mb: 1, '&:last-child': { mb: 0 }, color: 'text.secondary' }}>
              {item.includes('お問い合わせフォーム：') ? (
                <>
                  お問い合わせフォーム：
                  <Box
                    component="a"
                    href="https://dev.plsdb.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'primary.main',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    dev.plsdb.com/contact
                  </Box>
                </>
              ) : (
                item
              )}
            </Typography>
          ))}
        </Box>
      )}
      {text && <Typography sx={{ color: 'text.secondary' }}>{text}</Typography>}
    </Box>
  );

  const sections = [
    {
      title: '収集する情報',
      intro: '当サイトは、サービスの提供にあたり、以下の情報を取得・保管します：',
      items: [
        'Googleアカウントから提供される認証情報（メールアドレス）',
        'PULSEプロフィール情報（ユーザー名、自己紹介文、生年月日など）',
        'ゲームに関する活動情報（プレイ履歴、レビュー、評価など）',
        '利用状況データ（アクセスログなど）',
        'Cookieなどの技術的情報'
      ]
    },
    {
      title: '情報の利用目的',
      intro: '収集した情報は、以下の目的のために利用します：',
      items: [
        'ユーザー認証およびアカウント管理のため',
        'サービスの基本機能の提供のため',
        '世代別のゲーム体験分析と情報提供のため',
        'ユーザー体験の向上とサービス改善のため',
        'お問い合わせやサポート対応のため',
        '利用規約違反の調査や対応のため'
      ]
    },
    {
      title: '情報の管理',
      intro: '当サイトは、収集した個人情報の管理について、以下の対策を実施しています：',
      items: [
        'SSL/TLS暗号化通信の使用による通信経路の保護',
        'アクセス権限の設定と定期的な見直し',
        'セキュリティ対策の実施と定期的な更新',
        '個人情報の不正アクセス、紛失、破壊、改ざん、漏洩の防止',
        '従業者に対する教育・訓練の実施'
      ]
    },
    {
      title: '第三者提供',
      intro: '当サイトは、以下のいずれかに該当する場合を除き、収集した個人情報を第三者に提供することはありません：',
      items: [
        'ユーザーの明示的な同意がある場合',
        '法令に基づく開示請求があった場合',
        '人の生命、身体または財産の保護のために必要がある場合',
        'サービス提供に必要な範囲内で業務委託先に提供する場合',
        '事業承継に伴って提供する場合'
      ]
    },
    {
      title: '外部サービスの利用',
      intro: '当サイトでは、Google Analyticsを使用してアクセス解析を行い、ユーザーの行動を分析しています。また、Amazonのアソシエイトとして、適格販売に基づく報酬を受け取ることがあります。これらのサービスを利用する際には、Cookie等の技術を用いて情報収集が行われる場合があります。収集されたデータは各サービスのプライバシーポリシーに基づいて取り扱われます。',
    },
    {
      title: '個人情報の開示・訂正・利用停止',
      text: 'ユーザーは、当サイトが保有する自己の個人情報について、開示、訂正、利用停止、削除を求めることができます。'
    },
    {
      title: 'プライバシーポリシーの変更',
      text: '当サイトは、必要に応じて本プライバシーポリシーを変更することがあります。重要な変更がある場合は、サイト上での告知や登録されたメールアドレスへの通知により、ユーザーに変更内容を通知します。'
    },
    {
      title: 'お問い合わせ',
      intro: '本プライバシーポリシーに関するお問い合わせは、以下の運営者情報をご確認の上、お問い合わせフォームよりご連絡ください：',
      items: [
        'サイト名：PULSE（パルス）',
        '運営者：pulse developer',
        'お問い合わせフォーム：https://dev.plsdb.com/',
      ]
    }
  ];

  return (
    <>
      <Helmet>
        <title>プライバシーポリシー | {SITE_NAME}</title>
      </Helmet>
      <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 4, mt: 4, fontWeight: 'bold' }}>
        プライバシーポリシー
      </Typography>
      <Typography sx={{ mb: 4, color: 'text.secondary' }}>
        {SITE_NAME}（以下「当サイト」）は、ユーザーの個人情報の保護を重要な責務と考え、以下のプライバシーポリシーを定めています。
      </Typography>
      <Divider sx={{ mb: 4 }} />
      {sections.map((section, index) => (
        <Section key={index} {...section} index={index} />
      ))}
      <Divider sx={{ my: 4 }} />
      <Box sx={{ color: 'text.secondary' }}>
        <Typography variant="body2">制定日：2024年11月1日<br />最終更新日：2024年11月1日</Typography>
      </Box>
    </>
  );
};

export default PrivacyPolicyPage;