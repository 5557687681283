import React from 'react';
import { Typography, Box } from '@mui/material';

interface Company {
  company_id: number;
  name: string;
}

interface CopyrightProps {
  publishers: Company[];
  releaseYear?: string;
  variant?: 'section' | 'footer';
}

const Copyright: React.FC<CopyrightProps> = ({ publishers, releaseYear, variant = 'footer' }) => {
  if (!publishers || publishers.length === 0) return null;

  const currentYear = new Date().getFullYear();
  const publishYear = releaseYear ? new Date(releaseYear).getFullYear() : null;
  const publisherNames = publishers.map(pub => pub.name).join(', ');
  
  const yearDisplay = publishYear && publishYear <= currentYear
    ? (publishYear === currentYear 
      ? publishYear 
      : `${publishYear}-${currentYear}`)
    : currentYear;

  const styles = {
    footer: {
      display: 'inline-block',
      fontSize: '0.7rem',
      textAlign: 'left',
      // backgroundColor: 'rgba(255, 255, 255, 0.05)',
      padding: '4px 8px',
      borderRadius: '4px'
    },
    section: {
      display: 'block',
      fontSize: '0.65rem',
      textAlign: 'right',
      color: 'text.disabled',
      mt: 1
    }
  };

  return (
    <Box sx={{ mt: variant === 'footer' ? 4 : 0 }}>
      <Typography 
        variant="caption" 
        color="text.secondary"
        sx={styles[variant]}
      >
        {`© ${yearDisplay} ${publisherNames}`}
      </Typography>
    </Box>
  );
};

export default Copyright;
