import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { createGameList } from '../../services/contents_api';

interface CreateGameListProps {
  open: boolean;
  onClose: () => void;
  onListCreated: () => void;
}

const CreateGameList: React.FC<CreateGameListProps> = ({ open, onClose, onListCreated }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [listType, setListType] = useState(1);
  const [visibility, setVisibility] = useState(1);

  const isDevelopment = process.env.NODE_ENV === 'development';

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await createGameList({
        title,
        description,
        list_type: listType,
        visibility
      });
      onListCreated();
      onClose();
      setTitle('');
      setDescription('');
      setListType(1);
      setVisibility(1);
    } catch (error) {
      console.error('リストの作成に失敗しました:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle sx={{ pb: 1 }}>新規リストを作成</DialogTitle>
        <DialogContent sx={{ py: 1 }}>
          <TextField
            autoFocus
            size="small"
            margin="dense"
            label="タイトル"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            sx={{ mb: 1 }}
          />
          <TextField
            size="small"
            margin="dense"
            label="説明"
            fullWidth
            multiline
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 1 }}
          />
          <FormControl fullWidth margin="dense" size="small" sx={{ mb: 1 }}>
            <InputLabel>リストタイプ</InputLabel>
            <Select
              value={listType}
              label="リストタイプ"
              onChange={(e) => setListType(Number(e.target.value))}
            >
              <MenuItem value={1}>コレクション</MenuItem>
              <MenuItem value={2}>ランキング</MenuItem>
              {/* <MenuItem value={3}>プレイ予定</MenuItem> */}
            </Select>
          </FormControl>
          {isDevelopment && (
            <FormControl fullWidth margin="dense" size="small">
              <InputLabel>公開設定</InputLabel>
              <Select
                value={visibility}
                label="公開設定"
                onChange={(e) => setVisibility(Number(e.target.value))}
              >
                <MenuItem value={1}>公開</MenuItem>
                <MenuItem value={0}>非公開</MenuItem>
              </Select>
            </FormControl>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="submit" variant="contained">作成</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CreateGameList; 