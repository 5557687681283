import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
} from '@mui/material';
import { addGameDescription } from '../../services/api';

interface DescriptionUploadDialogProps {
  open: boolean;
  onClose: () => void;
  gameId: number;
  onUploadComplete: () => void;
  initialDescription: {
    description: string | null;
    story: string | null;
  } | null;
}

const DescriptionUploadDialog: React.FC<DescriptionUploadDialogProps> = ({
  open,
  onClose,
  gameId,
  onUploadComplete,
  initialDescription
}) => {
  const [description, setDescription] = useState(initialDescription?.description || '');
  const [story, setStory] = useState(initialDescription?.story || '');
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await addGameDescription({
        game_id: gameId,
        description: description || null,
        story: story || null,
      });
      onUploadComplete();
    } catch (error) {
      console.error('概要の追加に失敗しました:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>概要の追加・編集</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <TextField
            label="概要"
            multiline
            rows={4}
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            label="ストーリー"
            multiline
            rows={4}
            fullWidth
            value={story}
            onChange={(e) => setStory(e.target.value)}
            variant="outlined"
            margin="normal"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          disabled={submitting}
        >
          {submitting ? '送信中...' : '保存'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionUploadDialog;