import React, { useEffect } from 'react';
import { Box, Typography, Container, Paper } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import BuildIcon from '@mui/icons-material/Build';
import { maintenanceConfig } from '../config/maintenance';

const MaintenancePage = () => {
  useEffect(() => {
    document.title = 'メンテナンス中 - PULSE';
    const meta = document.createElement('meta');
    meta.httpEquiv = 'status';
    meta.content = '503 Service Temporarily Unavailable';
    document.getElementsByTagName('head')[0].appendChild(meta);

    return () => {
      document.getElementsByTagName('head')[0].removeChild(meta);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>メンテナンス中 - PULSE</title>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container maxWidth="sm" sx={{ mt: 8 }}>
        <Paper 
          elevation={3} 
          sx={{ 
            p: 4, 
            textAlign: 'center',
            borderRadius: 2,
            backgroundColor: 'background.paper'
          }}
        >
          <BuildIcon sx={{ fontSize: 60, color: 'primary.main', mb: 2 }} />
          <Typography variant="h4" component="h1" gutterBottom>
            メンテナンス中
          </Typography>
          <Typography variant="body1" color="text.secondary" paragraph>
            現在、システムのメンテナンスを実施しています。
            ご不便をおかけして申し訳ございません。
          </Typography>
          <Typography variant="body2" color="text.secondary">
            しばらくしてから再度アクセスをお願いいたします。
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Typography variant="caption" color="text.secondary">
              予定終了時刻：{maintenanceConfig.maintenanceEndTime}
            </Typography>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default MaintenancePage;