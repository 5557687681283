import React, { useEffect } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const AdvertisementResponsive: React.FC = () => {
  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Adsense error:', error);
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, width: '100%' }}>
      <ins
        className="adsbygoogle"
        style={{ 
          display: 'block',
          width: '100%',
          minWidth: '320px',
          maxWidth: '100%',
        }}
        data-ad-client="ca-pub-6000060497265595"
        data-ad-slot="9797170918"
        data-ad-format="auto"
        data-full-width-responsive="false"
      />
    </Box>
  );
};

export default AdvertisementResponsive;