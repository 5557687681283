import React, { useState, useEffect } from 'react';
import { Button, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Slider, Typography, Box, useMediaQuery, useTheme, IconButton, Tooltip, ListItemIcon, ListItemText, InputAdornment, Chip, Divider } from '@mui/material';
import { setGameStatus, setGameReview } from '../services/api';
import ConfirmDialog from './dialogs/ConfirmDialog';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import NotStartedOutlinedIcon from '@mui/icons-material/NotStartedOutlined';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CreateIcon from '@mui/icons-material/Create';
import Tips from './dialogs/Tips';
import { useNavigate } from 'react-router-dom';

interface GameStatusReviewProps {
  gameId: number;
  initialStatus: number | null;
  initialScore: number | null;
  initialReviewText: string | null;
  initialPlayStartDate: string | null;
  initialPlayEndDate: string | null;
  initialPlayTime: number | null;
  initialSubStatus: number[];
  initialScoreTags: number[]; // この行を追加
  onStatusChange: (
    newStatus: number | null,
    newScore: number | null,
    newReviewText: string | null,
    newPlayStartDate: string | null,
    newPlayEndDate: string | null,
    newPlayTime: number | null,
    newSubStatus: number[],
    newScoreTags: number[] // この行を追加
  ) => void;
}

const GameStatusReview: React.FC<GameStatusReviewProps> = ({
  gameId,
  initialStatus,
  initialScore,
  initialReviewText,
  initialPlayStartDate,
  initialPlayEndDate,
  initialPlayTime,
  initialSubStatus,
  initialScoreTags,
  onStatusChange
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [status, setStatus] = useState<number | null>(initialStatus);
  const [score, setScore] = useState<number | null>(initialScore);
  const [reviewText, setReviewText] = useState<string | null>(initialReviewText);
  const [openDialog, setOpenDialog] = useState(false);
  const [tempScore, setTempScore] = useState<number | null>(initialScore);
  const [tempReviewText, setTempReviewText] = useState<string | null>(initialReviewText);
  const [isDialogDirty, setIsDialogDirty] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [playStartDate, setPlayStartDate] = useState<string | null>(initialPlayStartDate);
  const [playEndDate, setPlayEndDate] = useState<string | null>(initialPlayEndDate);
  const [playTime, setPlayTime] = useState<number | null>(initialPlayTime);
  const [tempPlayStartDate, setTempPlayStartDate] = useState<string | null>(initialPlayStartDate);
  const [tempPlayEndDate, setTempPlayEndDate] = useState<string | null>(initialPlayEndDate);
  const [tempPlayTime, setTempPlayTime] = useState<number | null>(initialPlayTime);
  const [subStatus, setSubStatus] = useState<number[]>(initialSubStatus);
  const [scoreTags, setScoreTags] = useState<number[]>(initialScoreTags || []);
  const [tempScoreTags, setTempScoreTags] = useState<number[]>(initialScoreTags || []);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const navigate = useNavigate();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const checkLoginStatus = () => {
      const token = localStorage.getItem('token');
      setIsLoggedIn(!!token);
    };
    checkLoginStatus();
  }, []);

  useEffect(() => {
    setStatus(initialStatus);
    setScore(initialScore);
    setReviewText(initialReviewText);
    setTempScore(initialScore);
    setTempReviewText(initialReviewText);
    setPlayStartDate(initialPlayStartDate);
    setPlayEndDate(initialPlayEndDate);
    setPlayTime(initialPlayTime);
    setTempPlayStartDate(initialPlayStartDate);
    setTempPlayEndDate(initialPlayEndDate);
    setTempPlayTime(initialPlayTime);
    setSubStatus(initialSubStatus);
    setScoreTags(initialScoreTags || []);
    setTempScoreTags(initialScoreTags || []); // この行を追加
  }, [initialStatus, initialScore, initialReviewText, initialPlayStartDate, initialPlayEndDate, initialPlayTime, initialSubStatus, initialScoreTags]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusChange = async (newStatus: number | null) => {
    if (!isLoggedIn) {
      navigate('/login');
      return;
    }

    if (newStatus === null) {
      setOpenDeleteConfirmDialog(true);
    } else {
      try {
        await setGameStatus(gameId, newStatus);
        setStatus(newStatus);
        setSubStatus([]); // サブステータスをリセット
        onStatusChange(newStatus, score, reviewText, playStartDate, playEndDate, playTime, [], scoreTags);
        
        // ステータスが1（プレイ済）、2（プレイ中）、3（積みゲー）の場合、レビューダイアログを表示
        if ([1, 2, 3].includes(newStatus)) {
          handleReviewClick();
        }
      } catch (error: any) {
        console.error('ゲームステータスの更新に失敗しました', error);
        setErrorMessage(error.message);
        setOpenErrorDialog(true);
      }
      handleClose();
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      await setGameStatus(gameId, null);
      setStatus(null);
      setScore(null);
      setReviewText(null);
      setPlayStartDate(null);
      setPlayEndDate(null);
      setPlayTime(null);
      setSubStatus([]);
      setScoreTags([]);
      onStatusChange(null, null, null, null, null, null, [], []);
    } catch (error) {
      console.error('ゲームステータスの削除に失敗しました', error);
    }
    setOpenDeleteConfirmDialog(false);
    handleClose();
  };

  const handleDialogClose = async (save: boolean) => {
    if (!save && isDialogDirty) {
      setOpenConfirmDialog(true);
      return;
    }

    if (save && status !== null) {
      try {
        await setGameReview(gameId, tempScore, tempReviewText, tempPlayStartDate, tempPlayEndDate, tempPlayTime, tempScoreTags);
        setScore(tempScore);
        setReviewText(tempReviewText);
        setPlayStartDate(tempPlayStartDate);
        setPlayEndDate(tempPlayEndDate);
        setPlayTime(tempPlayTime);
        setScoreTags(tempScoreTags);
        onStatusChange(status, tempScore, tempReviewText, tempPlayStartDate, tempPlayEndDate, tempPlayTime, subStatus, tempScoreTags);
      } catch (error) {
        console.error('レビューの更新に失敗しました', error);
      }
    } else {
      // キャンセル時に元の値に戻す
      setTempScore(score);
      setTempReviewText(reviewText);
      setTempPlayStartDate(playStartDate);
      setTempPlayEndDate(playEndDate);
      setTempPlayTime(playTime);
      setTempScoreTags(scoreTags);
    }
    setOpenDialog(false);
    setIsDialogDirty(false);
  };

  const handleConfirmDialogClose = (confirm: boolean) => {
    setOpenConfirmDialog(false);
    if (confirm) {
      setOpenDialog(false);
      setIsDialogDirty(false);
      setTempScore(score);
      setTempReviewText(reviewText);
      setTempPlayStartDate(playStartDate);
      setTempPlayEndDate(playEndDate);
      setTempPlayTime(playTime);
    }
  };

  const handleScoreChange = (event: Event, newValue: number | number[]) => {
    setTempScore(newValue as number);
    setIsDialogDirty(true);
  };

  const handleReviewTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempReviewText(e.target.value);
    setIsDialogDirty(true);
  };

  const getStatusIcon = (statusCode: number | null) => {
    switch (statusCode) {
      case 1: return <CheckCircleOutlineIcon fontSize="small" sx={{ color: 'primary.main' }} />;
      case 2: return <PlayCircleOutlineIcon fontSize="small" sx={{ color: 'info.main' }} />;
      case 3: return <PauseCircleOutlineIcon fontSize="small" sx={{ color: 'warning.main' }} />;
      case 4: return <NotStartedOutlinedIcon fontSize="small" sx={{ color: '#b99a71' }} />;
      case 5: return <StarBorderIcon fontSize="small" sx={{ color: 'secondary.main' }} />;
      default: return <AddCircleOutlineIcon fontSize="small" sx={{ color: 'text.secondary' }} />;
    }
  };

  const getStatusText = (statusCode: number | null) => {
    switch (statusCode) {
      case 1: return 'プレイ済';
      case 2: return 'プレイ中';
      case 3: return '中断';
      case 4: return '積みゲー';
      case 5: return '気になる';
      default: return 'ステータスを設定';
    }
  };

  const scoreMarks = [
    { value: 1, label: '1.0' },
    { value: 2, label: 'poor' },
    { value: 3, label: '3.0' },
    { value: 4, label: 'good' },
    { value: 5, label: '5.0' },
  ];

  const getScoreText = (score: number | null | undefined) => {
    if (score === null || score === undefined) {
      return '未評価';
    }
    const numericScore = typeof score === 'string' ? parseFloat(score) : score;
    return isNaN(numericScore) ? '未評価' : numericScore.toFixed(1);
  };

  const handleReviewClick = () => {
    setTempScore(score);
    setTempReviewText(reviewText);
    setTempPlayStartDate(playStartDate);
    setTempPlayEndDate(playEndDate);
    setTempPlayTime(playTime);
    setTempScoreTags(scoreTags); // ここで初期化
    setOpenDialog(true);
  };

  const reviewCorrectionTips = (
    <Box>
      <Typography variant="body2" paragraph>
        ・レビュー補正とは、あなたの評価に大きく影響を与えている可能性のある要因を示すものです。
      </Typography>
      <Typography variant="body2" paragraph>
        ・確信がなくても構いません。「もしかしたら」という程度の直感も大切です。
      </Typography>
      <Typography variant="body2" paragraph>
        ・自分では気づきにくいバイアスもあります。他ユーザーとの評価に乖離を感じる状況などで自問し、思い当たる点があれば選択してください。
      </Typography>
      <Typography variant="body2" paragraph>
        ・これはスコアの妥当性を示すものではなく、あなたの評価の背景やゲーム体験を他のユーザーに表明するためのものです。
      </Typography>
      <Typography variant="body2" paragraph>
        ・正直に、かつ慎重に選択することで、あなたのレビューの信頼性と他のユーザーの理解が深まります。
      </Typography>
      <Typography variant="body2" paragraph>
        ・自身の評価プロセスを振り返ることで、より深い洞察が得られるかもしれません。
      </Typography>
      <Typography variant="body2" paragraph>
        ・全てのレビューで選択する必要はありません。スコアに大きく関与している可能性がある場合でのみ設定してください。
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Chip
            label="コンテキスト補正"
            color="primary"
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Typography variant="body2">
            ゲームを取り巻く背景や状況を考慮に入れた評価要因です。
            個人的な趣向や体験とは異なり、より広い文脈でゲームの価値を評価する際に使用します。
          </Typography>
        </Box>
        <Box>
          <Chip
            label="エントリー補正"
            color="primary"
            variant="outlined"
            sx={{ mb: 1 }}
          />
          <Typography variant="body2">
            あなたにとって新しい、または不慣れなゲーム要素が評価に影響を与えている可能性を示すものです。初めて遊ぶジャンルやシリーズは新鮮さや驚きにより、同じ領域のゲームと比較してスコアが高くなる傾向があります。
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Button
          onClick={handleClick}
          startIcon={getStatusIcon(status)}
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: '12px',
            padding: '8px 16px',
            color: 'text.primary',
            '&:hover': {
              backgroundColor: 'action.hover',
            },
          }}
        >
          {getStatusText(status)}
        </Button>
        {/* ステータスが1（プレイ済）、2（プレイ中）、3（積みゲー）の場合にレビューアイコンを表示 */}
        {(status === 1 || status === 2 || status === 3) && (
          <Tooltip title="レビューを書く" arrow>
            <IconButton
              onClick={handleReviewClick}
              sx={{
                backgroundColor: 'background.paper',
                borderRadius: '12px',
                padding: '8px',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
              }}
            >
              <CreateIcon fontSize="small" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            mt: 1,
          }
        }}
      >
        <MenuItem onClick={() => handleStatusChange(1)}>
          <ListItemIcon>{getStatusIcon(1)}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>プレイ済み</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange(2)}>
          <ListItemIcon>{getStatusIcon(2)}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>プレイ中</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange(3)}>
          <ListItemIcon>{getStatusIcon(3)}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>中断</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange(4)}>
          <ListItemIcon>{getStatusIcon(4)}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>積みゲー</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => handleStatusChange(5)}>
          <ListItemIcon>{getStatusIcon(5)}</ListItemIcon>
          <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>気になる</ListItemText>
        </MenuItem>
        {status !== null && (
          <MenuItem onClick={() => handleStatusChange(null)}>
            <ListItemIcon>
              <RemoveCircleOutlineIcon fontSize="small" sx={{ color: 'error.main' }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'text.primary' }}>ステータスを削除</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <Dialog 
        open={openDialog} 
        onClose={() => handleDialogClose(false)}
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>ゲームレビュー</DialogTitle>
        <DialogContent>
          <Box sx={{ my: 2 }}>
            <Typography gutterBottom>スコア（3.0が普通）</Typography>
            <Slider
              value={tempScore ?? 3}
              onChange={handleScoreChange}
              aria-labelledby="game-score-slider"
              step={0.1}
              marks={scoreMarks}
              min={1}
              max={5}
              valueLabelDisplay="auto"
              sx={{
                '& .MuiSlider-thumb': {
                  color: tempScore !== null ? 'primary.main' : 'grey.500',
                },
                '& .MuiSlider-track': {
                  color: tempScore !== null ? 'primary.main' : 'grey.500',
                },
                '& .MuiSlider-rail': {
                  color: tempScore !== null ? 'primary.light' : 'grey.300',
                },
              }}
            />
            <Typography gutterBottom>
              現在のスコア: {getScoreText(tempScore)}
            </Typography>
          </Box>
          <TextField
            autoFocus
            margin="dense"
            id="review"
            label="レビュー"
            type="text"
            fullWidth
            multiline
            rows={fullScreen ? 6 : 4}
            value={tempReviewText || ''}
            onChange={handleReviewTextChange}
            size="small"
          />
          <Box sx={{ display: 'flex', gap: 2 }}>
            <TextField
              label="プレイ開始日"
              type="date"
              value={tempPlayStartDate || ''}
              onChange={(e) => {
                const input = e.target.value;
                setTempPlayStartDate(input || null);
                setIsDialogDirty(true);
              }}
              margin="dense"
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: "9999-12-31" }}
              size="small"
              sx={{ flex: 1 }}
            />
            <TextField
              label="プレイ終了日"
              type="date"
              value={tempPlayEndDate || ''}
              onChange={(e) => {
                const input = e.target.value;
                setTempPlayEndDate(input || null);
                setIsDialogDirty(true);
              }}
              margin="dense"
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: "9999-12-31" }}
              size="small"
              sx={{ flex: 1 }}
            />
          </Box>
          <TextField
            margin="dense"
            id="playTime"
            label="プレイ時間"
            type="number"
            fullWidth
            value={tempPlayTime || ''}
            onChange={(e) => {
              setTempPlayTime(Number(e.target.value));
              setIsDialogDirty(true);
            }}
            InputProps={{
              endAdornment: <InputAdornment position="end">時間</InputAdornment>,
            }}
            size="small"
          />
          <Box sx={{ my: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography gutterBottom>スコア補正タグ</Typography>
              <Tips title="レビュー補正について" content={reviewCorrectionTips} />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {[
                { id: 1, label: '趣味趣向補正' },
                { id: 2, label: '思い出補正' },
                { id: 3, label: 'IP・シリーズ補正' },
                { id: 4, label: 'デベロッパー補正' },
                { id: 5, label: 'ソフトウェアバージョン補正' },
                { id: 6, label: 'マルチプレイ補正' },
                { id: 7, label: 'エントリー補正' },
                { id: 8, label: 'コンテキスト補正' },
                { id: 9, label: '下方バイアス' },
                { id: 0, label: 'その他の上方補正要因' },
              ].map((tag) => (
                <Chip
                  key={tag.id}
                  label={tag.label}
                  onClick={() => {
                    const newTempScoreTags = tempScoreTags.includes(tag.id)
                      ? tempScoreTags.filter((id) => id !== tag.id)
                      : [...tempScoreTags, tag.id];
                    setTempScoreTags(newTempScoreTags);
                    setIsDialogDirty(true);
                  }}
                  color={tempScoreTags.includes(tag.id) ? 'primary' : 'default'}
                  size="small"
                />
              ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose(true)} color="primary" variant="contained">保存</Button>
          <Button onClick={() => handleDialogClose(false)} color="inherit">キャンセル</Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={openConfirmDialog}
        title="確認"
        message="変更内容が保存されていません。キャンセルしてもよろしいですか？"
        onConfirm={() => handleConfirmDialogClose(true)}
        onCancel={() => handleConfirmDialogClose(false)}
        confirmText="OK"
        cancelText="編集を続ける"
      />

      <ConfirmDialog
        open={openDeleteConfirmDialog}
        title="ステータス削除の確認"
        message="ステータスを削除すると、レビューとスコアも削除されます。本当に削除しますか？"
        onConfirm={handleDeleteConfirm}
        onCancel={() => setOpenDeleteConfirmDialog(false)}
        confirmText="削除"
        cancelText="キャンセル"
      />

      <Dialog
        open={openErrorDialog}
        onClose={() => setOpenErrorDialog(false)}
      >
        <DialogTitle>エラー</DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenErrorDialog(false)} color="primary">
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GameStatusReview;
