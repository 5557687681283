import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, IconButton, Typography, Slider, Select, MenuItem, FormControl, InputLabel, Switch, FormControlLabel } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RestoreIcon from '@mui/icons-material/Restore';

interface PhraseImageGeneratorProps {
  open: boolean;
  onClose: () => void;
  phrase: string;
  gameName: string;
}

interface TextStyle {
  fontSize: number;
  fontFamily: string;
  lineHeight: number;
  charsPerLine: number;
  verticalPosition: number;
  horizontalPosition: number;
  gameNameFontSize: number;
  gameNameHorizontalPosition: number;
}

const DEFAULT_TEXT_STYLE: TextStyle = {
  fontSize: 55,
  fontFamily: '"Noto Serif JP", serif',
  lineHeight: 1.5,
  charsPerLine: 30,
  verticalPosition: 60,
  horizontalPosition: 50,
  gameNameFontSize: 24,
  gameNameHorizontalPosition: 50
};

const LabeledSlider = ({ 
  label, 
  value, 
  onChange, 
  min, 
  max, 
  step, 
  defaultValue 
}: { 
  label: string;
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
  step: number;
  defaultValue: number;
}) => (
  <Box sx={{ mb: 1 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Typography variant="body2">{label}</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="body2" color="text.secondary">{value}</Typography>
        <IconButton 
          size="small" 
          onClick={() => onChange(defaultValue)}
          sx={{ padding: '2px' }}
        >
          <RestoreIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
    <Slider
      size="small"
      value={value}
      onChange={(_, value) => onChange(value as number)}
      min={min}
      max={max}
      step={step}
      valueLabelDisplay="auto"
    />
  </Box>
);

const PhraseImageGenerator: React.FC<PhraseImageGeneratorProps> = ({
  open,
  onClose,
  phrase,
  gameName
}) => {
  const [loading, setLoading] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [generatedImage, setGeneratedImage] = useState<string | null>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [textStyle, setTextStyle] = useState<TextStyle>(DEFAULT_TEXT_STYLE);
  const [showOverlay, setShowOverlay] = useState(true);
  const [overlayOpacity, setOverlayOpacity] = useState(0.5);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  useEffect(() => {
    if (previewUrl && imgRef.current) {
      imgRef.current.onload = () => {
        generateImage();
      };
    }
  }, [previewUrl]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      // ファイルサイズチェック (10MB)
      if (file.size > 10 * 1024 * 1024) {
        alert('ファイルサイズは10MB以下にしてください');
        return;
      }

      // 画像ファイルかチェック
      if (!file.type.startsWith('image/')) {
        alert('画像ファイルを選択してください');
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
          setPreviewUrl(img.src);
        };
        img.src = e.target?.result as string;
      };
      reader.readAsDataURL(file);
    }
  };

  const generateImage = () => {
    if (!imgRef.current || !canvasRef.current) return;
    setLoading(true);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    try {
      // キャンバスのサイズを固定
      canvas.width = 1200;
      canvas.height = 630;

      // 背景を黒で塗りつぶし
      ctx.fillStyle = 'black';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 画像のアスペクト比を維持しながら描画
      const img = imgRef.current;
      const imageAspectRatio = img.naturalWidth / img.naturalHeight;
      const canvasAspectRatio = canvas.width / canvas.height;

      let drawWidth = canvas.width;
      let drawHeight = canvas.width / imageAspectRatio;
      let drawX = 0;
      let drawY = (canvas.height - drawHeight) / 2;

      if (drawHeight < canvas.height) {
        drawHeight = canvas.height;
        drawWidth = drawHeight * imageAspectRatio;
        drawX = (canvas.width - drawWidth) / 2;
        drawY = 0;
      }

      ctx.drawImage(img, drawX, drawY, drawWidth, drawHeight);

      // オーバーレイ（オプション）
      if (showOverlay) {
        ctx.fillStyle = `rgba(0, 0, 0, ${overlayOpacity})`;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
      }

      // テキストスタイル設定
      ctx.fillStyle = 'white';
      ctx.textAlign = 'center';
      ctx.font = `bold ${textStyle.fontSize}px ${textStyle.fontFamily}`;
      
      // フレーズを描画
      const lines = wrapText(ctx, phrase, textStyle.charsPerLine);
      const lineHeight = textStyle.fontSize * textStyle.lineHeight;
      const totalHeight = lines.length * lineHeight;
      
      // 垂直位置の計算
      const verticalSpace = canvas.height - totalHeight;
      const startY = (verticalSpace * textStyle.verticalPosition / 100);

      // 水平位置の設定
      const horizontalPosition = (canvas.width * textStyle.horizontalPosition / 100);
      ctx.textAlign = 'center';
      
      // フキストを描画
      lines.forEach((line, index) => {
        ctx.fillText(line, horizontalPosition, startY + (index * lineHeight));
      });

      // ゲーム名を描画（位置とサイズを調整可能に）
      ctx.font = `300 ${textStyle.gameNameFontSize}px ${textStyle.fontFamily}`;
      const gameNameX = (canvas.width * textStyle.gameNameHorizontalPosition / 100);
      ctx.fillText(gameName, gameNameX, canvas.height - 30);

      setGeneratedImage(canvas.toDataURL('image/png'));
    } catch (error) {
      console.error('画像生成エラー:', error);
      alert('画像の生成に失敗しました');
    }
    setLoading(false);
  };

  const downloadImage = () => {
    if (!generatedImage) return;

    // モバイルデバイスの場合は新しいタブで開く
    if (/Android|webOS|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      const byteString = atob(generatedImage.split(',')[1]);
      const mimeString = generatedImage.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl);
      setTimeout(() => {
        URL.revokeObjectURL(blobUrl);
      }, 100);
    } else {
      // PCの場合は従来通りダウンロード
      const link = document.createElement('a');
      link.download = `${gameName}-phrase.png`;
      link.href = generatedImage;
      link.click();
    }
  };

  const wrapText = (ctx: CanvasRenderingContext2D, text: string, charsPerLine: number) => {
    const chars = text.split('');
    const lines = [];
    let currentLine = '';

    for (let i = 0; i < chars.length; i++) {
      currentLine += chars[i];
      
      // 指定文字数に達したか、最後の文字の場合
      if (currentLine.length >= charsPerLine || i === chars.length - 1) {
        lines.push(currentLine);
        currentLine = '';
      }
    }
    
    return lines;
  };

  useEffect(() => {
    if (previewUrl && imgRef.current) {
      generateImage();
    }
  }, [textStyle, showOverlay, overlayOpacity]);

  const handleDownload = () => {
    setDownloadDialogOpen(true);
  };

  const confirmDownload = () => {
    setDownloadDialogOpen(false);
    downloadImage();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        画像生成
        <IconButton onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileSelect}
            ref={fileInputRef}
            style={{ display: 'none' }}
          />
          
          {!previewUrl ? (
            <Box
              sx={{
                border: '2px dashed #ccc',
                borderRadius: 2,
                p: 3,
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
              onClick={() => fileInputRef.current?.click()}
            >
              <CloudUploadIcon sx={{ fontSize: 40, color: 'text.secondary' }} />
              <Typography variant="body1" sx={{ mt: 1 }}>
                クリックして背景画像を選択
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                または画像をドラッグ＆ドロップ
              </Typography>
            </Box>
          ) : (
            <Box sx={{ 
              mt: 1, 
              display: 'flex', 
              flexDirection: { xs: 'column', md: 'row' },
              gap: 2 
            }}>
              <Box sx={{ 
                width: { xs: '100%', md: '300px' },
                order: { xs: 2, md: 1 }
              }}>
                <Typography variant="subtitle1" gutterBottom>スタイル設定</Typography>
                
                <FormControl fullWidth margin="dense">
                  <InputLabel>フォント</InputLabel>
                  <Select
                    size="small"
                    value={textStyle.fontFamily}
                    onChange={(e) => setTextStyle({
                      ...textStyle,
                      fontFamily: e.target.value as string
                    })}
                  >
                    <MenuItem value='"Noto Serif JP", serif'>明朝体</MenuItem>
                    <MenuItem value='"Noto Sans JP", sans-serif'>ゴシック体</MenuItem>
                  </Select>
                </FormControl>

                <LabeledSlider
                  label="文字サイズ"
                  value={textStyle.fontSize}
                  onChange={(value) => setTextStyle({ ...textStyle, fontSize: value })}
                  min={24}
                  max={72}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.fontSize}
                />

                <LabeledSlider
                  label="行間"
                  value={textStyle.lineHeight}
                  onChange={(value) => setTextStyle({ ...textStyle, lineHeight: value })}
                  min={1}
                  max={2}
                  step={0.1}
                  defaultValue={DEFAULT_TEXT_STYLE.lineHeight}
                />

                <LabeledSlider
                  label="1行あたりの文字数"
                  value={textStyle.charsPerLine}
                  onChange={(value) => setTextStyle({ ...textStyle, charsPerLine: value })}
                  min={3}
                  max={30}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.charsPerLine}
                />

                <FormControlLabel
                  control={
                    <Switch
                      checked={showOverlay}
                      onChange={(e) => setShowOverlay(e.target.checked)}
                    />
                  }
                  label="背景オーバーレイ"
                />

                {showOverlay && (
                  <LabeledSlider
                    label="オーバーレイ濃度"
                    value={overlayOpacity}
                    onChange={setOverlayOpacity}
                    min={0}
                    max={1}
                    step={0.1}
                    defaultValue={0.5}
                  />
                )}

                <LabeledSlider
                  label="垂直位置"
                  value={textStyle.verticalPosition}
                  onChange={(value) => setTextStyle({ ...textStyle, verticalPosition: value })}
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.verticalPosition}
                />

                <LabeledSlider
                  label="水平位置"
                  value={textStyle.horizontalPosition}
                  onChange={(value) => setTextStyle({ ...textStyle, horizontalPosition: value })}
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.horizontalPosition}
                />

                <Typography variant="subtitle1" sx={{ mt: 2 }} gutterBottom>ゲーム名設定</Typography>

                <LabeledSlider
                  label="ゲーム名サイズ"
                  value={textStyle.gameNameFontSize}
                  onChange={(value) => setTextStyle({ ...textStyle, gameNameFontSize: value })}
                  min={16}
                  max={48}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.gameNameFontSize}
                />

                <LabeledSlider
                  label="ゲーム名水平位置"
                  value={textStyle.gameNameHorizontalPosition}
                  onChange={(value) => setTextStyle({ ...textStyle, gameNameHorizontalPosition: value })}
                  min={0}
                  max={100}
                  step={1}
                  defaultValue={DEFAULT_TEXT_STYLE.gameNameHorizontalPosition}
                />

                <Box sx={{ mt: 2, display: 'flex', gap: 1 }}>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    画像を変更
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={handleDownload}
                    sx={{
                      background: (theme) => theme.palette.primary.main,
                      color: 'background.default',
                      '&:hover': {
                        background: (theme) => theme.palette.primary.dark,
                      }
                    }}
                  >
                    ダウンロード
                  </Button>
                </Box>
              </Box>

              <Box sx={{ 
                flex: 1,
                order: { xs: 1, md: 2 },
                display: 'flex', 
                flexDirection: 'column', 
                gap: 1,
                minWidth: 0
              }}>
                <img 
                  ref={imgRef}
                  src={previewUrl}
                  alt="Preview" 
                  style={{ 
                    width: '100%',
                    maxHeight: '300px',
                    objectFit: 'contain',
                    borderRadius: '4px'
                  }} 
                />

                {generatedImage && (
                  <img 
                    src={generatedImage}
                    alt="Generated" 
                    style={{ 
                      width: '100%',
                      maxHeight: '300px',
                      objectFit: 'contain',
                      borderRadius: '4px'
                    }} 
                  />
                )}

                <Box sx={{ 
                  display: { xs: 'flex', md: 'none' }, 
                  gap: 1, 
                  mt: 1 
                }}>
                  <Button
                    fullWidth
                    size="small"
                    variant="outlined"
                    onClick={() => fileInputRef.current?.click()}
                  >
                    画像を変更
                  </Button>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={handleDownload}
                  >
                    ダウンロード
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          <canvas ref={canvasRef} style={{ display: 'none' }} />
        </Box>
      </DialogContent>

      <Dialog
        open={downloadDialogOpen}
        onClose={() => setDownloadDialogOpen(false)}
      >
        <DialogTitle>利用規約</DialogTitle>
        <DialogContent>
          <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '0.7rem', lineHeight: 1.1 }}>
            サムネイルはダウンロードしてシェアテキストと併せて利用してください。
            <br />
            画像単体での利用はお控えください。
          </Typography>
          <Button
            size="small"
            fullWidth
            onClick={confirmDownload}
            sx={{ fontSize: '0.75rem', py: 0.5, textTransform: 'none', mt: 2 }}
          >
            ダウンロード
          </Button>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default PhraseImageGenerator;
