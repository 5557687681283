import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import AdvertisementResponsive from './Ad_GA_res';

interface AdSet {
  mainLink: string;
  mainImage: string;
  trackingImage?: string;
  width?: string;
  height?: string;
}

const AD_SETS: AdSet[] = [
  {
    // Amazon特典付き
    mainLink: "https://amzn.to/4fETcTb",
    mainImage: "/image/adv/amazon_special.jpg",
  },
  {
    // DMMブックス
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FH9R02+6HW+3SZUAP",
    mainImage: "https://www26.a8.net/svt/bgt?aid=241123637936&wid=001&eno=01&mid=s00000000842023010000&mc=1",
    trackingImage: "https://www16.a8.net/0.gif?a8mat=3ZK46T+FH9R02+6HW+3SZUAP",
    width: "300",
    height: "250"
  },
  {
    // ABEMAプレミアム
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FD3QJE+4EKC+614CX",
    mainImage: "https://www27.a8.net/svt/bgt?aid=241123637929&wid=002&eno=01&mid=s00000020550001013000&mc=1",
    trackingImage: "https://www17.a8.net/0.gif?a8mat=3ZK46T+FD3QJE+4EKC+614CX",
    width: "300",
    height: "250"
  },
  {
    // 楽天Kobo
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FDP5DE+5EOC+5YZ75",
    mainImage: "https://www29.a8.net/svt/bgt?aid=241123637930&wid=001&eno=01&mid=s00000025230001003000&mc=1",
    trackingImage: "https://www17.a8.net/0.gif?a8mat=3ZK46T+FDP5DE+5EOC+5YZ75",
    width: "300",
    height: "250"
  },
  {
    // Spoon
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46T+FOEY9E+5JFQ+5ZMCH",
    mainImage: "https://www28.a8.net/svt/bgt?aid=241123637948&wid=001&eno=01&mid=s00000025847001006000&mc=1",
    trackingImage: "https://www16.a8.net/0.gif?a8mat=3ZK46T+FOEY9E+5JFQ+5ZMCH",
    width: "300",
    height: "250"
  },
  {
    // グッズEC
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK46W+7CXXQY+4RNG+60OXD",
    mainImage: "https://www23.a8.net/svt/bgt?aid=241123640445&wid=002&eno=01&mid=s00000022246001011000&mc=1",
    trackingImage: "https://www15.a8.net/0.gif?a8mat=3ZK46W+7CXXQY+4RNG+60OXD",
    width: "300",
    height: "250"
  },
  {
    // ネットオフ買取
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK471+RDZDM+1SOW+2TA9FL",
    mainImage: "https://www20.a8.net/svt/bgt?aid=241123645046&wid=002&eno=01&mid=s00000008384017012000&mc=1",
    trackingImage: "https://www11.a8.net/0.gif?a8mat=3ZK471+RDZDM+1SOW+2TA9FL",
    width: "300",
    height: "250"
  },
  {
    // GEOオンライン
    mainLink: "https://px.a8.net/svt/ejp?a8mat=3ZK4YT+2PN4RE+4J34+60OXD",
    mainImage: "https://www25.a8.net/svt/bgt?aid=241124645164&wid=002&eno=01&mid=s00000021136001011000&mc=1",
    trackingImage: "https://www15.a8.net/0.gif?a8mat=3ZK4YT+2PN4RE+4J34+60OXD",
    width: "300",
    height: "250"
  }
];

const RectangleAdvertisement: React.FC = () => {
  const shouldShowAdSense = useMemo(() => Math.random() < 0.9, []);
  const selectedAd = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * AD_SETS.length);
    return AD_SETS[randomIndex];
  }, []);

  if (shouldShowAdSense) {
    return <AdvertisementResponsive />;
  }

  return (
    <Box 
      sx={{ 
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mb: 3,
        width: '100%',
        bgcolor: 'rgba(255, 255, 255, 0.02)',
        borderRadius: 2,
        py: 2,
        px: 1.5,
        position: 'relative'
      }}
    >
      <Typography 
        variant="caption" 
        sx={{ 
          position: 'absolute',
          top: 8,
          right: 8,
          fontSize: '0.6rem',
          color: 'text.secondary',
          bgcolor: 'rgba(70, 70, 70, 0.75)',
          px: 0.8,
          py: 0.2,
          borderRadius: 0.5,
          letterSpacing: '0.05em',
          lineHeight: 1
        }}
      >
        PR
      </Typography>
      <Box 
        sx={{ 
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          overflow: 'hidden',
          borderRadius: 1,
        }}
      >
        <a 
          href={selectedAd.mainLink} 
          rel="nofollow noopener noreferrer" 
          target="_blank"
        >
          <img 
            style={{ 
              maxWidth: '100%', 
              height: 'auto',
              border: '0',
              display: 'block',
            }}
            width={selectedAd.width}
            height={selectedAd.height}
            alt="Advertisement" 
            src={selectedAd.mainImage}
          />
        </a>
      </Box>
      {selectedAd.trackingImage && (
        <img 
          style={{ border: '0' }}
          width="1" 
          height="1" 
          src={selectedAd.trackingImage} 
          alt="" 
        />
      )}
    </Box>
  );
};

export default RectangleAdvertisement;