import React from 'react';
import { Typography, Box, Paper, Avatar } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleLoginButton from '../components/auth/GoogleLogin';
import { useLocation } from 'react-router-dom';

const LoginPage: React.FC = () => {
  const location = useLocation();
  const from = (location.state as { from?: string })?.from || '/profile';

  const handleLoginSuccess = (user: any) => {
    // ヘッダーの状態を更新するためのカスタムイベントを発火
    const event = new CustomEvent('loginSuccess', { detail: user });
    window.dispatchEvent(event);
    
    // 元のページまたはプロフィールページにリダイレクト
    window.location.href = from;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 12 }}>
      <Paper elevation={1} sx={{ p: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: 400, width: '100%', borderRadius: 2 }}>
        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5" component="h1" gutterBottom>
          ログイン
        </Typography>
        <Box sx={{ mt: 1.5 }}>
          <GoogleLoginButton onLoginSuccess={handleLoginSuccess} redirectPath={from} />
        </Box>
      </Paper>
    </Box>
  );
};

export default LoginPage;