import React from 'react';
import { Dialog, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

interface YoutubeViewerProps {
  open: boolean;
  onClose: () => void;
  videoId: string;
}

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    margin: theme.spacing(2),
    maxWidth: 'none',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    overflow: 'hidden',
    // PCでの最大サイズを設定
    [theme.breakpoints.up('md')]: {
      width: 'min(1280px, calc(100vw - 48px))',
      height: 'min(720px, calc(100vh - 48px))',
    },
    // タブレットでのサイズ
    [theme.breakpoints.between('sm', 'md')]: {
      width: 'calc(100vw - 48px)',
      height: 'calc((100vw - 48px) * 0.5625)', // 16:9のアスペクト比
    },
    // モバイルでのサイズ
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100vw',
      height: '80vh',
    },
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: -40,
  top: 0,
  color: '#fff',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  zIndex: 1,
  [theme.breakpoints.down('sm')]: {
    right: 8,
    top: 8,
  },
}));

const YoutubeViewer: React.FC<YoutubeViewerProps> = ({ open, onClose, videoId }) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      fullWidth
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        <iframe
          src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            width: '100%',
            height: '100%',
            border: 0,
          }}
        />
      </Box>
    </StyledDialog>
  );
};

export default YoutubeViewer;