import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Box } from '@mui/material';
import { GameListItem } from '../../services/contents_api';

interface SortableGameItemProps {
  item: GameListItem;
}

export const SortableGameItem: React.FC<SortableGameItemProps> = ({ item }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      sx={{
        position: 'relative',
        aspectRatio: '3/4',
        borderRadius: 1,
        overflow: 'hidden',
        bgcolor: 'background.paper',
        boxShadow: isDragging ? '0 0 12px 4px rgba(217, 255, 0, 0.65)' : 1,
        cursor: 'grab',
        '&:active': {
          cursor: 'grabbing',
        },
        zIndex: transform ? 1 : 'auto',
        touchAction: 'none',
        WebkitTouchCallout: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
      }}
    >
      <Box
        component="img"
        src={item.game_cover || '/image/no_image.png'}
        alt={item.game_name}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          pointerEvents: 'none',
        }}
      />
    </Box>
  );
}; 