import React, { useEffect } from 'react';
import { Box } from '@mui/material';

declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

const Advertisement336: React.FC = () => {
  useEffect(() => {
    try {
      if (window.adsbygoogle) {
        window.adsbygoogle.push({});
      }
    } catch (error) {
      console.error('Adsense error:', error);
    }
  }, []);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: '336px',
          height: '280px',
        }}
        data-ad-client="ca-pub-6000060497265595"
        data-ad-slot="5006852275"
      />
    </Box>
  );
};

export default Advertisement336;