import React, { useState, useEffect } from 'react';
import { Box, Typography, ImageList, ImageListItem, useTheme, useMediaQuery, Modal, IconButton, Button } from '@mui/material';
import { getGameImages } from '../../services/api';
import ImageUploadButton from './ImageUploadButton';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

interface Image {
  id: number;
  url: string;
  original_url?: string;
  type: number;
  description: string | null;
  image_id: string;
  created_at: string;
  original_format: number;
}

interface GameGallerySectionProps {
  gameId: number;
}

const GameGallerySection: React.FC<GameGallerySectionProps> = ({ gameId }) => {
  const [images, setImages] = useState<Image[]>([]);
  const [selectedImage, setSelectedImage] = useState<Image | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchImages = async () => {
    try {
      const response = await getGameImages(gameId, 4);
      const imageArray = response.results || response || [];
      console.log('Fetched images:', imageArray);
      setImages(imageArray);
    } catch (error) {
      console.error('画像の取得に失敗しました:', error);
      setImages([]);
    }
  };

  useEffect(() => {
    fetchImages();
  }, [gameId]);

  const handleUploadComplete = (imageUrls: string[]) => {
    // 全ての画像のアップロードが完了してから画像一覧を再取得
    fetchImages();
  };

  const handleImageClick = (image: Image) => {
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleViewOriginal = (e: React.MouseEvent, url: string) => {
    e.stopPropagation();
    window.open(url, '_blank');
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" component="h2">
            ギャラリー
          </Typography>
          <IconButton
            component={Link}
            to={`/game/${gameId}/images`}
            size="small"
            sx={{ 
              ml: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
        {process.env.NODE_ENV === 'development' && (
          <ImageUploadButton 
            gameId={gameId}
            onUploadComplete={handleUploadComplete}
            onError={(error) => console.error(error)}
          />
        )}
      </Box>

      {(!images || images.length === 0) ? (
        <Typography variant="body2" color="text.secondary">
          このゲームの画像はまだ登録されていません。
        </Typography>
      ) : (
        <>
          <ImageList 
            variant="quilted"
            cols={isMobile ? 2 : 2} 
            gap={8}
            sx={{ 
              mb: 0,
              overflow: 'visible',
              '& .MuiImageListItem-root': {
                overflow: 'hidden',
                borderRadius: 1,
                '& img': {
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  transform: 'none',
                  position: 'static',
                }
              }
            }}
          >
            {images.map((image: Image) => (
              <ImageListItem 
                key={image.id}
                onClick={() => handleImageClick(image)}
                sx={{
                  aspectRatio: '16/9',
                  '& img': {
                    borderRadius: 1,
                  },
                  cursor: 'pointer',
                  '&:hover': {
                    opacity: 0.8,
                    transition: 'opacity 0.2s',
                  },
                }}
              >
                <img
                  src={image.url}
                  alt={image.description || ''}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
          
          {images.length >= 4 && (
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Link
                to={`/game/${gameId}/images`}
                style={{
                  fontSize: '0.65rem',
                  fontWeight: 'normal',
                  lineHeight: 1.5,
                  cursor: 'pointer',
                  textDecoration: 'none',
                  color: theme.palette.primary.main
                }}
              >
                全ての画像を見る
              </Link>
            </Box>
          )}
        </>
      )}

      <Modal
        open={!!selectedImage}
        onClose={handleCloseModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        <Box 
          sx={{ 
            position: 'relative',
            maxWidth: '90vw',
            maxHeight: '90vh',
            width: 'auto',
            height: 'auto',
          }}
          onClick={handleCloseModal}
        >
          <Box
            component="img"
            src={selectedImage?.url}
            alt={selectedImage?.description || ''}
            sx={{
              maxWidth: '100%',
              maxHeight: '90vh',
              width: 'auto',
              height: 'auto',
              minWidth: '300px',
              minHeight: '300px',
              objectFit: 'contain',
              outline: 'none',
              userSelect: 'none',
              borderRadius: 2,
            }}
          />
          {selectedImage?.original_url && selectedImage.original_format > 0 && (
            <Button
              onClick={(e) => handleViewOriginal(e, selectedImage.original_url!)}
              sx={{
                position: 'absolute',
                bottom: -24,  // 少し上に調整
                right: 0,
                color: 'white',
                fontSize: '0.6rem', 
                textTransform: 'none',
                padding: '0 1px',
                minWidth: 'unset',
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'transparent',
                  opacity: 0.8,
                },
              }}
            >
              View original
            </Button>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default GameGallerySection;