import React from 'react';
import { 
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Typography,
  Box,
  PopoverProps,
  SxProps,
  Theme
} from '@mui/material';

interface MenuOption {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  iconColor?: string;
}

interface PopoverMenuProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  title?: string;
  options: MenuOption[];
  anchorOrigin?: PopoverProps['anchorOrigin'];
  transformOrigin?: PopoverProps['transformOrigin'];
  sx?: SxProps<Theme>;
}

const PopoverMenu: React.FC<PopoverMenuProps> = ({
  open,
  anchorEl,
  onClose,
  title,
  options,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  sx
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        sx: {
          width: '200px',
          boxShadow: '0 8px 24px rgba(140,149,159,0.2)',
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: '6px',
          mt: 0.5,
          ...sx
        }
      }}
    >
      <Box sx={{ p: 1 }}>
        {title && (
          <Typography variant="subtitle2" sx={{ px: 1, py: 0.5 }}>
            {title}
          </Typography>
        )}
        <List sx={{ p: 0 }}>
          {options.map((option, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton 
                onClick={option.onClick}
                sx={{ 
                  py: 0.5,
                  borderRadius: 1,
                  '&:hover': { backgroundColor: 'action.hover' }
                }}
              >
                <ListItemIcon sx={{ minWidth: 36, color: option.iconColor }}>
                  {React.cloneElement(option.icon as React.ReactElement, { 
                    sx: { fontSize: '1.2rem' } 
                  })}
                </ListItemIcon>
                <ListItemText 
                  primary={option.label} 
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    sx: { fontWeight: 500 }
                  }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Popover>
  );
};

export default PopoverMenu; 