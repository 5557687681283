import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Button } from '@mui/material';
import ReviewList from './ReviewList';
import { getGameReviews } from '../../services/api';
import { Review } from '../../types/Review';
import { Link } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { IconButton } from '@mui/material';

interface ReviewSectionProps {
  gameId: number;
  limit?: number;
  onTotalReviewsChange?: (total: number) => void;
}

const ReviewSection: React.FC<ReviewSectionProps> = ({ gameId, limit, onTotalReviewsChange }) => {
  const [reviews, setReviews] = useState<Review[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const { results, count } = await getGameReviews(gameId, undefined, limit);
        setReviews(results);
        if (onTotalReviewsChange) {
          onTotalReviewsChange(count);
        }
      } catch (error) {
        console.error('レビューの取得に失敗しました', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReviews();
  }, [gameId, limit, onTotalReviewsChange]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        mb: 2 
      }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="h6" component="h2">
            ユーザーレビュー
          </Typography>
          <IconButton
            component={Link}
            to={`/game/${gameId}/reviews`}
            size="small"
            sx={{ 
              ml: 0.5,
              color: 'text.secondary',
              '&:hover': {
                color: 'primary.main',
              }
            }}
          >
            <NavigateNextIcon />
          </IconButton>
        </Box>
      </Box>
      
      {reviews.length === 0 ? (
        <Typography variant="body2" color="text.secondary">
          このゲームのレビューはまだ登録されていません。
        </Typography>
      ) : (
        <>
          <ReviewList reviews={reviews} />
          {reviews.length >= 3 && (
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button
                component={Link}
                to={`/game/${gameId}/reviews`}
                variant="text"
                size="small"
              >
                すべてのレビューを見る
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ReviewSection;